// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
gHeader

gHeader

Markup:
<header class="gHeader">
	<div class="gHeader_inner">
		<h1 class="gHeader_logo"><a href="/"><img src="/assets/images/global/gHeader_logo.png" alt="株式会社文理"></a></h1><!-- /.gHeader_logo -->
		<nav class="gHeader_nav">
			<ul class="gHeader_btns">
				<li class="gHeader_btn"><a href="#" class="gHeader_btn-blue">一般の方へ</a></li><!-- /.gHeader_btn -->
				<li class="gHeader_btn"><a href="#" class="gHeader_btn-green">塾・学校の先生へ</a></li><!-- /.gHeader_btn -->
				<li class="gHeader_btn"><a href="#" class="gHeader_btn-pink">書店の方へ</a></li><!-- /.gHeader_btn -->
			</ul><!-- /.gHeader_btns -->
			<ul class="gHeader_list">
				<li class="gHeader_item"><a href="/product">商品・サービス</a></li><!-- /.gHeader_item -->
				<li class="gHeader_item"><a href="/intro">事業紹介</a></li><!-- /.gHeader_item -->
				<li class="gHeader_item gHeader_item-down gHeader_item-icon">
					<a href="/guidance">会社案内</a>
					<ul class="gHeader_subList">
						<li class="gHeader_subItem"><a href="#">社長ご挨拶</a></li><!-- /.gHeader_subItem -->
						<li class="gHeader_subItem"><a href="#">文理の想い</a></li><!-- /.gHeader_subItem -->
						<li class="gHeader_subItem"><a href="#">教科書準拠と文理</a></li><!-- /.gHeader_subItem -->
						<li class="gHeader_subItem"><a href="#">文理の歩み</a></li><!-- /.gHeader_subItem -->
						<li class="gHeader_subItem"><a href="#">会社概要</a></li><!-- /.gHeader_subItem -->
					</ul><!-- /.gHeader_subList -->
				</li><!-- /.gHeader_item -->
				<li class="gHeader_item"><a href="/recruit">採用情報</a></li><!-- /.gHeader_item -->
				<li class="gHeader_item"><a href="/news">お知らせ</a></li><!-- /.gHeader_item -->
			</ul><!-- /.gHeader_list -->
		</nav><!-- /.gHeader_nav -->
		<div class="gHeader_navBtn gHeader_navBtn"><a href="javascript: void(0)"><span></span></a></div><!-- /.gHeader_navBtn -->
	</div><!-- /.gHeader_inner -->
</header><!-- /.gHeader -->

Styleguide #{$guideNum}
*/
.gHeader {
	$this: &;
	background: $yellow-light;

	&_inner {
		margin: 0 auto;
		padding: 12px 40px 0;
		max-width: $psd-width;
		display: grid;
		grid-template-columns: 168px auto;
		align-items: center;
		justify-content: space-between;
	}

	&_logo {
		& > a {
			flex-shrink: 0;
			& > img {
				aspect-ratio: 168/45;
				object-fit: contain;
			}
		}
	}

	&_nav {
		z-index: z(gHeader_nav);
	}

	&_btns {
		margin: 0;
		display: grid;
		grid-template-columns: repeat(3, 143px);
		justify-content: flex-end;
		gap: 0 10px;
	}

	&_btn {
		& > a {
			display: block;
			padding: 6px 10px 7px;
			color: #fff;
			font-size: rem(14px, pc);
			font-weight: bold;
			text-align: center;
			line-height: 1;
			text-decoration: none;
			border-radius: 999px;
		}

		//  color
		//----------------------------------------------------------------------
		&-blue {
			background: $blue;
		}
		&-green {
			background: $green;
		}
		&-pink {
			background: $pink;
		}
	}

	&_list {
		display: flex;
		margin: 1px 0 0;
		& > :first-child {
			& > a {
				padding-left: 0;
			}
		}
		& > :last-child {
			& > a {
				padding-right: 0;
			}
		}
	}
	&_item {
		&-down {}
		&-isActive {}
		&-icon {}
	}
	&_itemLink {
		display: block;
		padding: 15px;
		color: inherit;
		font-weight: bold;
		text-decoration: none;
		transition: all .4s;
		@media (hover: hover) {
			&:hover {
				color: $green;
			}
		}
		&-cursorDefault {
			cursor: default;
		}
	}

	&_subList {}

	&_subItem {
		& > a {
			display: block;
			padding: 10px;
			text-align: center;
			color: inherit;
			font-weight: bold;
			border-top: 1px solid $yellow-exLight;
			text-decoration: none;
			transition: all .4s;
			@media (hover: hover) {
				&:hover {
					color: $green;
					opacity: 1;
				}
			}
		}
	}

	&_navBtn {}
}

@media screen and (min-width: $breakpoint-sp + 1){
	.gHeader {
		$this: &;

		&_inner {}

		&_logo {}

		&_nav {}

		&_btns {}

		&_btn {}

		&_list {}
		&_item {
			&-down {
				position: relative;
			}
			&-isActive {
				& > #{$this}_subList {
					top: 40px; // 表示させたい位置
					opacity: 1;
					visibility: visible;
				}
			}
			&-icon {
				position: relative;
				&::before,
				&::after {
					opacity: 0;
				}
				&::before { // 鉛筆の線の指定
					content: '';
					width: 0px;
					height: 1px;
					background: $green;
					position: absolute;
					top: 56px;
					// left: 31px;
					left: calc(50% - 16px);
					// transform: translateX(-50%);
					transition: all .4s;
				}
				&::after { // 鉛筆の指定
					content: '';
					width: 14px;
					height: 14px;
					position: absolute;
					top: 43px;
					// left: 31px;
					left: calc(50% - 16px);
					background: url(/assets/images/global/pencil.png);
					transition: all .4s;
				}
				@keyframes pencilAnime {
					0% {
						transform: rotate(0deg);
					}
					20% {
						transform: rotate(-15deg);
					}
					40% {
						transform: rotate(0deg);
					}
					60% {
						transform: rotate(-15deg);
					}
					80% {
						transform: rotate(0deg);
					}
				}
				&:hover {
					&::before,
					&::after {
						opacity: 1;
					}
					&::before { // 鉛筆の線の指定
						width: 16px;
					}
					&::after { // 鉛筆の指定
						// left: 47px;
						left: 50%;
						display: inline-block;
						animation: pencilAnime; // keyframes
						animation-duration: .4s; // アニメーション時間
						transform-origin: left bottom; // 支点を指定 x, y
					}
				}
			}
		}
		&_itemLink {}

		&_subList {
			// display: none;
			position: absolute;
			top: 35px; // 動きを付けるための指定
			left: 50%;
			transform: translate(-50%, 0);
			width: 210px;
			background: #fff;
			border-radius: 20px;
			opacity: 0;
			visibility: hidden;
			transition: all .4s;
			box-shadow: 0px 10px 20px 0px rgba(4, 0, 0, 0.1);
			& > :first-child {
				& > a {
					border-top: none;
				}
			}
		}

		&_subItem {}

		&_navBtn {}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gHeader {
		$this: &;

		&_inner {
			padding: 24px 10px 31px;
			display: grid;
			grid-template-columns: 168px 33px;
			justify-content: space-between;
			gap: 0 20px;
		}

		&_logo {
			z-index: z(gHeader_logo);
			& > a {
			}
		}

		&_nav {
			// display: none;
			position: fixed;
			inset: 0;
			display: flex;
			flex-direction: column;
			gap: 30px 0;
			padding: 100px 20px 30px;
			//width: 80vw; // 表示面積
			background: #fff;
			z-index: z(gHeader_nav);
			transform: translateX(101%); // 非表示
			transition: all .4s;
			overflow-y: auto;
			&-isActive {
				transform: translateX(0); // 表示
			}
		}

		&_btns {
			order: 2;
			display: flex;
			flex-direction: column;
			gap: 20px 0;
		}

		&_btn {
			& > a {
				display: flex;
				justify-content: center;
				align-items: center;
				min-height: 40px;
				font-size: rem(13px, sp);
			}
		}

		&_list {
			order: 1;
			display: flex;
			flex-direction: column;
			& > :first-child {
				& > a {
					padding-left: 10px;
				}
			}
			& > :last-child {
				& > a {
					padding-right: 10px;
				}
			}
		}

		&_item {
			&-isActive {
				& > span {
					// &::after {
					// 	transform: rotate(180deg);
					// }
					&::after {
						transform: rotate(0)!important;
						// opacity: 0;
					}
				}
				& > #{$this}_subList {
					// display: block;
				}
			}
			&-icon {
				& > span {
					position: relative;
					// &::after {
					// 	@include icons($icons-caret-down);
					// 	font-size: .7em;
					// 	font-weight: bold;
					// 	line-height: 1;
					// 	position: absolute;
					// 	top: 20px;
					// 	right: 20px;
					// }
					&::before,
					&::after {
						content: '';
						width: 9px;
						height: 1px;
						background: $black;
						margin: -1px 0 0 -4px;
						position: absolute;
						top: 50%;
						right: 10px;
						transition: transform .4s;
					}
					&::after {
						transform: rotate(90deg);
					}
				}
			}
		}

		&_itemLink {
			padding: 10px;
			font-size: rem(15px, sp);
			border-bottom: 1px solid $yellow-dark;
		}

		&_subList {
			display: none;
			margin: 0 0 0 15px;
			// transition: initial;
		}

		&_subItem {
			& > a {
				border-top: none;
				border-bottom: 1px solid $yellow-dark;
				text-align: left;
			}
		}

		&_navBtn {
			display: block;
			z-index: z(gHeader_navBtn);
			& > a {
				display: block;
				text-decoration: none;
				transition: all .4s;
				position: relative;
				&:before,
				&:after {
					content: '';
					display: block;
					width: 33px;
					height: 2.5px;
					background: $txtColor;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: all .4s;
					border-radius: 1px;
				}
				&:before {
					transform: translateY(-6px);
				}
				&:after {
					transform: translateY(6px);
				}
			}
			&-isActive {
				& > a {
					transition: all .4s;
					&:before {
						transform: translateY(0) rotate(150deg);
					}
					&:after {
						transform: translateY(0) rotate(-150deg);
					}
				}
			}
		}
	}
}
