// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
block

block

Markup:
<footer class="gFooter">
	<div class="gFooter_inner">
		<div class="gFooter_info">
			<div class="gFooter_logo"><a href="/"><img src="/assets/images/global/gFooter_logo.png" alt="株式会社文理" loading="lazy"></a></div><!-- /.gFooter_logo -->
			<div class="gFooter_copy">&copy;<script type="text/javascript">document.write(new Date().getFullYear())</script> Bunri Co., Ltd.</div><!-- /.gFooter_copy -->
		</div><!-- /.gFooter_info -->
		<nav class="gFooter_nav">
			<ul class="gFooter_blocks">
				<li class="gFooter_block">
					<dl class="gFooter_item">
						<dt><a href="#">商品・サービス</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">事業紹介</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">会社案内</a></dt>
						<dd><a href="#">社長ご挨拶</a></dd>
						<dd><a href="#">文理の想い</a></dd>
						<dd><a href="#">教科書準拠と文理</a></dd>
						<dd><a href="#">文理の歩み</a></dd>
						<dd><a href="#">会社概要</a></dd>
					</dl><!-- /.gFooter_item -->
				</li><!-- /.gFooter_block -->
				<li class="gFooter_block">
					<dl class="gFooter_item">
						<dt><a href="#">採用情報</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="/news">お知らせ</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">一般の方へ</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">塾・学校の先生へ</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">書店の方へ</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">著作権について</a></dt>
					</dl><!-- /.gFooter_item -->
				</li><!-- /.gFooter_block -->
				<li class="gFooter_block">
					<dl class="gFooter_item">
						<dt><a href="#">個人情報の取り扱いについて</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">免責事項</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#">お問い合わせ・よくある質問</a></dt>
					</dl><!-- /.gFooter_item -->
					<dl class="gFooter_item">
						<dt><a href="#" target="_blank" rel="noopener noreferrer">学研ホールディングス</a></dt>
					</dl><!-- /.gFooter_item -->
				</li><!-- /.gFooter_block -->
			</ul><!-- /.gFooter_blocks -->
		</nav><!-- /.gFooter_nav -->
	</div><!-- /.gFooter_inner -->
</footer><!-- /.gFooter -->

Styleguide #{$guideNum}
*/
.gFooter {
	$this: &;
	background: $green;
	border-radius: 30px 30px 0 0;

	&_inner {
		@include content-width();
		padding: 40px 0;
		display: grid;
		grid-template-columns: 186px 1fr;
		gap: 0 111px;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&_logo {
		& > a {
			flex-shrink: 0;
			& > img {
				aspect-ratio: 186/51;
				object-fit: contain;
			}
		}
	}

	&_copy {
		color: #fff;
		@include font-lato();
		font-weight: bold;
		font-size: rem(14px, pc);
	}

	&_nav {}

	&_blocks {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	&_block {
		padding: 0 0 5px;
		border-left: 1px solid #fff;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_item {
		margin: 10px 0 0 30px;
		& > dt, dd {
			& > a, span {
				display: block;
				color: #fff;
				font-size: rem(14px, pc);
				text-decoration: none;
			}
			& > a[target="_blank"] {
				&::after {
					margin-left: 10px;
					@include icons($icons-blank);
					font-size: .714em;
					font-weight: normal;
					line-height: 1;
				}
			}
		}
		& > dt {
			& > a, span {
				padding-left: 14px;
				font-weight: bold;
				position: relative;
				&::before {
					@include icons($icons-caret-right);
					font-size: .5em;
					font-weight: normal;
					line-height: 1;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
				}
			}
			& > span {
				cursor: default;
			}
		}
		& > dd {
			margin-left: 14px;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.gFooter {
		$this: &;

		&_inner {
			display: block;
			padding: 30px 0;
		}

		&_info {
			text-align: center;
		}

		&_logo {}

		&_copy {
			margin: 15px 0 0;
			font-size: rem(14px, sp);
		}

		&_nav {
			display: none;
		}

		&_blocks {}

		&_block {}

		&_item {}
	}
}
