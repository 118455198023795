// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
newsHeader

newsHeader

Markup:
<div class="newsHeader">
	<div class="newsHeader_info">
		<p class="newsHeader_date">2024年1月25日</p><!-- /.newsHeader_date -->
		<p class="newsHeader_cate">カテゴリ1</p><!-- /.newsHeader_cate -->
	</div><!-- /.newsHeader_info -->
	<h1 class="newsHeader_title">サーバーメンテナンスのお知らせ</h1><!-- /.newsHeader_title -->
</div><!-- /.newsHeader -->

Styleguide #{$guideNum}
*/
.newsHeader {
	$this: &;

	&_inner {
		@include content-width();
		padding: 40px 40px 35px;
		background: #fff;
		border-radius: 30px;
	}

	&_info {
		display: flex;
		gap: 0 14px;
	}

	&_date {
		margin: 0;
	}

	&_cate {}

	&_title {
		margin: 10px 0 0;
		font-weight: bold;
		font-size: rem(30px, pc);
		@include line-height(46, 30);
	}
}

@media screen and (max-width: $breakpoint-sp){
	.newsHeader {
		$this: &;

		&_inner {
			padding: 30px 30px 25px;
		}

		&_info {
			display: inline-block;
		}

		&_date {}

		&_cate {
			margin: 5px 0 0;
		}

		&_title {
			font-size: rem(25px, sp);
		}
	}
}
