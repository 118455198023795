// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

[data-tab] {}
[data-tab-btn] {
	background: $yellow-exLight;
}
[data-tab-btn-active] {
	color: #fff!important;
	background: $green;
}
[data-tab-content] {
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: opacity .4s;
}
[data-tab-content-snap] {
	transition: none !important;
}
[data-tab-content-active] {
	height: auto;
	opacity: 1;
}


@media screen and (max-width: $breakpoint-sp){

}