// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
chronology

chronology

Markup:


Styleguide #{$guideNum}
*/
.chronology {
	$this: &;
	margin: 30px 0 0;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		padding: 0 0 80px;
		position: relative;
		&::before {
			content: '';
			width: 1px;
			height: 100%;
			background: $black03;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: -1;
		}
		& > :first-child {
			margin-top: 0;
		}
	}

	&_item {
		margin: 60px 0 0;
		display: grid;
		grid-template-columns: 415px auto 415px;
		gap: 0 19px;
		align-items: center;
	}

	&_left,
	&_right {
		& > #{$this}_panel {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 19px;
				height: 21px;
			}
		}
	}

	&_left {
		& > #{$this}_panel {
			&::before {
				right: -19px;
				background: $blue;
				clip-path: polygon(0 0, 0% 100%, 100% 50%); // 右三角形
			}
		}
	}

	&_panel {
		padding: 20px;
		background: #fff;
		border-radius: 20px;
	}

	&_txt,
	&_year {
		margin: 0;
		font-weight: bold;
	}

	&_txt {}

	&_year {
		padding: 2px 0;
		font-size: rem(24px, pc);
		background: $yellow-light;
		text-align: center;
		& > span {
			font-size: rem(16px, pc);
		}
	}

	&_right {
		& > #{$this}_panel {
			&::before {
				left: -19px;
				background: $green;
				clip-path: polygon(0 50%, 100% 0, 100% 100%); // 左三角形
			}
		}
	}

	&_imgTxt {
		display: grid;
		grid-template-columns: 177px auto;
		align-items: center;
		gap: 0 20px;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_img {
		& > img {
			width: 100%;
			height: 100%;
			aspect-ratio: 177/157;
			object-fit: contain;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.chronology {
		$this: &;

		&_list {
			padding: 0 0 50px;
		}

		&_item {
			margin: 30px 0 0;
			display: grid;
			grid-template-columns: 1fr auto 1fr;
			gap: 0 15px;
			align-items: center;
		}

		&_left,
		&_right {
			& > #{$this}_panel {
				&::before {
					width: 11px;
					height: 13px;
				}
			}
		}

		&_left {
			& > #{$this}_panel {
				&::before {
					right: -11px;
				}
			}
		}

		&_panel {
			padding: 10px;
		}

		&_txt {}

		&_year {
			font-size: rem(18px, sp);
		}

		&_right {
			& > #{$this}_panel {
				&::before {
					left: -11px;
				}
			}
		}

		&_imgTxt {
			margin: 5px 0 0;
			display: flex;
			flex-direction: column;
			align-items: initial;
			gap: 5px 0;
		}

		&_img {
			margin: 0 auto;
			max-width: 137px;
		}
	}
}
