// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
btn

ボタン

Markup:
<div class="btns btns-hr">
	<ul class="btns_list">
		<li class="btns_item btns_item-4-12"><a href="" class="btn {$modifiers}">ボタン</a></li>
		<li class="btns_item btns_item-4-12"><button class="btn {$modifiers}">ボタン</button></li>
	</ul>
</div><!-- /.btns -->

.btn-red - $red
.btn-redLight - $redLight
.btn-blue - $blue
.btn-blueLight - $blueLight
.btn-gray - $gray
.btn-grayLight - $grayLight
.btn-gray-border - $gray-border

Styleguide #{$guideNum}
*/
.btn {
	$this: &;
	// display: flex;
	// display: -webkit-flex;
	display: grid;
	gap: 0 15px;
	grid-template-columns: 1fr;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px;
	background: $gray;
	text-decoration: none;
	cursor: pointer;
	color: inherit;
	border: 1px solid transparent;
	border-radius: 999px;
	text-align: center;
	font-weight: bold;

	&[disabled] {
		opacity: .6 !important;
		cursor: default !important;
	}

	//  color
	//----------------------------------------------------------------------
	&-green {
		color: #fff;
		background: $green;

		// circle
		&:before,
		&:after {
			background: #fff;
		}
	}

	&-greenBorder {
		color: $green;
		background: #fff;
		border: 1px solid $green;

		// circle
		&:before,
		&:after {
			background: $green;
		}
	}

	&-blue {
		background: $blue;
	}

	&-pink {
		background: $pink;
	}

	//  size
	//----------------------------------------------------------------------
	&-s {
		padding: 6px;
		min-width: 145px;
		font-size: rem(14px, pc);
	}

	&-l {
		padding: 13px 10px;
		min-width: 440px;
		font-size: rem(20px, pc);
	}

	//  circle
	//----------------------------------------------------------------------
	&-beforeCircleS,
	&-beforeCircleL,
	&-afterCircleS,
	&-afterCircleL {
		&:before,
		&:after {
			content: '';
			// background: #fff;
			border-radius: 50%;
		}
	}

	&-beforeCircleS,
	&-afterCircleS {
		grid-template-columns: 10px 1fr 10px;
	}

	&-beforeCircleL,
	&-afterCircleL {
		grid-template-columns: 15px 1fr 15px;
	}

	&-beforeCircleS:before,
	&-afterCircleS:after {
		width: 10px;
		height: 10px;
	}

	&-beforeCircleL:before,
	&-afterCircleL:after {
		width: 15px;
		height: 15px;
	}
}

@media (hover:hover) {
	.btn {
		$this: &;
		transition: all .4s;
		&:hover {
			opacity: 1;
		}

		//  color
		//----------------------------------------------------------------------
		&-green {
			&:hover {
				color: $green;
				background: #fff;
				border: 1px solid $green;

				// circle
				&:before,
				&:after {
					background: $green;
				}
			}
		}

		&-greenBorder {
			&:hover {
				color: #fff;
				background: $green;
				border: 1px solid transparent;

				// circle
				&:before,
				&:after {
					background: #fff;
				}
			}
		}

		//  circle
		//----------------------------------------------------------------------
		// &-beforeCircleS,
		// &-beforeCircleL,
		// &-afterCircleS,
		// &-afterCircleL {
		// 	&:hover {
		// 		&:before,
		// 		&:after {
		// 			background: $green;
		// 		}
		// 	}
		// }
	}
}

@media screen and (min-width: $breakpoint-sp + 1){
	.btn {
		&:hover {
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.btn {
		$this: &;

		//  size
		//----------------------------------------------------------------------
		&-s {
			// min-width: initial;
		}

		&-l {
			min-width: 310px;
			font-size: rem(13px, sp);
		}
	}
}

