// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
pageHeaderSide

見出し1
サイドバーがある時、.contents_main直下で使用

Markup:
<div class="pageHeaderSide">
	<div class="pageHeaderSide_inner">
		<h1 class="pageHeaderSide_title">見出し1（.pageHeaderSide）<br>サイドバーがある時、.contents_main直下で使用</h1><!-- /.pageHeaderSide_title -->
	</div><!-- /.pageHeaderSide_inner -->
</div><!-- /.pageHeaderSide -->

Styleguide #{$guideNum}
*/
.pageHeaderSide {
	$this: &;
	margin: 0 0 40px;

	&_inner {
		@include content-width();
		padding: 57px 42px 57px 40px;
		background: #fff;
		border-radius: 30px;
	}

	&_title {
		padding-left: 40px;
		font-size: rem(30px, pc);
		font-weight: bold;
		@include line-height(46, 30);
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			width: 14px;
			height: 14px;
			background: $green;
			border-radius: 50%;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageHeaderSide {
		$this: &;

		&_inner {
			margin: 0;
			padding: 45px 30px 45px 30px;
		}

		&_title {
			padding-left: 30px;
			font-size: rem(25px, sp);
		}
	}
}
