// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
gHeadArea

gHeadArea

Markup:
{modifiers}

Styleguide #{$guideNum}
*/
.gHeadArea {
	$this: &;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: z(gHeadArea);
	background: transparent;
}

@media screen and (max-width: $breakpoint-sp){
	.gHeadArea {
		$this: &;
	}
}