// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
panel

panel

Markup:
{modifiers}

Styleguide #{$guideNum}
*/
.panel {
	$this: &;
	margin: 40px 0;
	padding: 20px;

	//  color
	//----------------------------------------------------------------------
	&-green {
		border: 1px solid $green;
	}

	&-red {
		border: 1px solid $red;
	}

	&-bgWhite {
		background: #fff;
	}

	//  radius
	//----------------------------------------------------------------------
	// &-radius {
	// 	border-radius: 30px;
	// }
}

@media screen and (max-width: $breakpoint-sp){
	.panel {
		$this: &;
	}
}