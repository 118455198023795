@font-face {
	font-family: "icons";
	src: url('/assets/fonts/iconfont/icons.eot');
	src: url('/assets/fonts/iconfont/icons.eot?#iefix') format('eot'),
		url('/assets/fonts/iconfont/icons.woff') format('woff'),
		url('/assets/fonts/iconfont/icons.ttf') format('truetype'),
		url('/assets/fonts/iconfont/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

//  font
//----------------------------------------------------------------------
@mixin icons($code) {
	content: $code;
	font-family: "icons";
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


	$icons-arrow-down: "\EA01";
	$icons-arrow-left: "\EA02";
	$icons-arrow-right: "\EA03";
	$icons-arrow-up: "\EA04";
	$icons-bag: "\EA05";
	$icons-blank: "\EA06";
	$icons-blockquote: "\EA07";
	$icons-book: "\EA08";
	$icons-calc: "\EA09";
	$icons-caret-down-thin: "\EA0A";
	$icons-caret-down: "\EA0B";
	$icons-caret-left-thin: "\EA0C";
	$icons-caret-left: "\EA0D";
	$icons-caret-right-thin: "\EA0E";
	$icons-caret-right: "\EA0F";
	$icons-caret-up-thin: "\EA10";
	$icons-caret-up: "\EA11";
	$icons-check: "\EA12";
	$icons-circleArrow-down: "\EA13";
	$icons-circleArrow-left: "\EA14";
	$icons-circleArrow-right: "\EA15";
	$icons-circleArrow-up: "\EA16";
	$icons-circleCheck: "\EA17";
	$icons-close: "\EA18";
	$icons-door: "\EA19";
	$icons-error: "\EA1A";
	$icons-halfArrow-down: "\EA1B";
	$icons-halfArrow-left: "\EA1C";
	$icons-halfArrow-right: "\EA1D";
	$icons-halfArrow-up: "\EA1E";
	$icons-home: "\EA1F";
	$icons-mail: "\EA20";
	$icons-phone: "\EA21";
	$icons-pin: "\EA22";



