// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
sideFrame

sideFrame

Markup:
<div class="sideFrame">
	<div class="sideFrame_header"><a href="" class="sideFrame_title">第1階層</a></div><!-- /.sideFrame_header -->
	<div class="sideFrame_body">
		<div class="treeMenu" data-tree></div><!-- /.tree -->
	</div><!-- /.sideFrame_body -->
</div><!-- /.sideFrame -->

Styleguide #{$guideNum}
*/

.sideFrame {
	$this: &;

	&_header {
		border-bottom: 1px solid $yellow-dark;
	}

	&_title {
		display: block;
		padding: 0 18px 13px;
		color: inherit;
		font-weight: bold;
		line-height: 1.6;
		text-decoration: none;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 7px;
			left: 0;
			width: 10px;
			height: 10px;
			background: $green;
			border-radius: 50%;
		}
	}

	&_body {
		margin: 15px 0 0;
		& > :first-child {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.sideFrame {
		$this: &;

		&_header {}

		&_title {
			padding: 0 0 13px 18px;
			font-size: rem(14px, sp);
			&::before {
				top: 6px;
			}
		}

		&_body {}
	}
}
