// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
layout

大枠のレイアウト

Markup:
{$modifiers}

Styleguide #{$guideNum}
*/

.wrapper {
	$this: &;
	min-width: calc($page-width + 40px);
	min-height: 100%;
	& > :first-child {
		margin-top: 0;
	}
}

.contents {
	$this: &;
	display: flex;
	// align-items: flex-start;
	display: -webkit-flex;
	gap: 0 40px;
	width: $page-width;
	margin: 40px auto 100px;
	& > :first-child {
		margin-top: 0;
	}
	&_main {
		flex-grow: 0;
		flex-shrink: 0;
		width: $main-width;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_side {
		// position: sticky;
		// top: 160px;
		// background: #000;
		flex-grow: 0;
		flex-shrink: 0;
		width: $side-width;
		padding-left: 40px;
		border-left: 1px solid $yellow-dark;
		& > :first-child {
			margin-top: 0;
		}
	}
	&-noSide {
		display: block;
		#{$this}_main {
			width: auto;
		}
		#{$this}_side {
			display: none !important;
		}
	}
}

.section {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
	&_inner {
		max-width: $page-width;
		margin: 0 auto;
		& > :first-child {
			margin-top: 0;
		}
	}
}

.sideSection {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.wrapper {
		$this: &;
		min-width: 100vw;
	}

	.contents {
		$this: &;
		// display: block;
		flex-direction: column;
		gap: 60px 0;
		width: auto;
		// margin: 25px 20px 40px;
		margin: 40px 10px 55px;
		&_main {
			width: auto;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_side {
			width: auto;
			padding: 0;
			border: none;
			& > :first-child {
				margin-top: 0;
			}
		}
		&-noSide {
		}
	}

	.section {
		$this: &;
		&_inner {}
	}

	.sideSection {
		$this: &;
	}
}