// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
floatingCookie

floatingCookie

Markup:
<div class="floatingCookie">
	<div class="floatingCookie_inner">
		<p class="floatingCookie_title">クッキーポリシーについて</p><!-- /.floatingCookie_title -->
		<p class="floatingCookie_txt">
			当ウェブサイトは、サービス向上の目的のためCookieを利用しています。<br>
			Cookieの利用に同意していただける場合は、同意ボタンをクリックしてください。<br>
			同意いただけない場合は、ただちにブラウザを閉じて閲覧を中止してください。
		</p><!-- /.floatingCookie_txt -->
		<p class="floatingCookie_link"><a href="" target="_blank" rel="noopener noreferrer">プライバシーポリシーについて</a></p><!-- /.floatingCookie_link -->
		<div class="btns mt0">
			<ul class="btns_list mt0">
				<li class="btns_item"><button class="btn btn-green btn-s btn-afterCircleS">同意</button></li>
			</ul>
		</div><!-- /.btns -->
	</div><!-- /.floatingCookie_inner -->
</div><!-- /.floatingCookie -->

Styleguide #{$guideNum}
*/
.floatingCookie {
	$this: &;
	// display: none;
	position: fixed;
	right: 18px;
	bottom: 100px;
	z-index: z(floatingCookie);
	color: $txtColor03;

	&_inner {
		padding: 20px 30px 30px;
		background: rgba(#fff, .9);
		border-radius: 30px;
	}

	&_title {
		margin: 0;
		font-size: rem(18px, pc);
		font-weight: bold;
	}

	&_txt {
		margin: 5px 0 0;
		font-size: rem(14px, pc);
	}

	&_link {
		margin: 10px 0 0;
		& > a {
			display: inline-block;
			margin-left: 12px;
			color: inherit;
			font-size: rem(14px, pc);
			text-underline-offset: 6px;
			position: relative;
			&::before {
				@include icons($icons-caret-right);
				font-size: .5em;
				font-weight: bold;
				position: absolute;
				top: 7px;
				left: -12px;
			}
		}
	}

	.btns_list {
		justify-content: flex-end;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.floatingCookie {
		$this: &;
		left: 10px;
		right: 10px;
		bottom: 42px;

		&_inner {
			padding: 20px 20px 20px 30px;
			.btns_list {
				justify-content: center;
			}
		}

		&_title {
			font-size: rem(18px, sp);
		}

		&_txt {
			font-size: rem(14px, sp);
		}

		&_link {
			& > a {
				font-size: rem(14px, sp);
			}
		}
	}
}
