// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
.iconTxt

アイコン付き文字

Markup:
<p class="{$modifiers}">アイコン付き文字</p>

Styleguide #{$guideNum}
*/
.iconTxt {
}
