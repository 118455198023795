// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
movePage

movePage

Markup:
<div class="movePage">
	<div class="movePage_inner">
		<ul class="movePage_list">
			<li class="movePage_item movePage_item-prev"><a href="#">前の記事へ</a></li><!-- /.movePage_item -->
			<li class="movePage_item"><a href="#">一覧へ戻る</a></li><!-- /.movePage_item -->
			<li class="movePage_item movePage_item-next"><a href="#">次の記事へ</a></li><!-- /.movePage_item -->
		</ul><!-- /.movePage_list -->
	</div><!-- /.movePage_inner -->
</div><!-- /.movePage -->

Styleguide #{$guideNum}
*/
.movePage {
	$this: &;
	margin: 55px 0 0;

	&_inner {
		& > :first-child {
			margin-top: 0;
		}
	}

	&_list {
		display: grid;
		grid-template-columns: 1fr 230px 1fr;
		padding: 10px;
		border-top: 1px solid $yellow-dark;
		border-bottom: 1px solid $yellow-dark;
	}

	&_item {
		& > a {
			display: block;
			padding: 25px 30px;
			color: $green;
			font-size: rem(14px, pc);
			font-weight: bold;
			text-decoration: none;
		}

		&-prev, &-next {
			& > a {
				position: relative;
			}
		}

		&-prev {
			& > a {
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
					width: 9px;
					height: 9px;
					background: $green;
					border-radius: 50%;
				}
			}
		}

		&-list {
			text-align: center;
			border-left: 1px solid $yellow-dark;
			border-right: 1px solid $yellow-dark;
		}

		&-next {
			text-align: right;
			& > a {
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate(0, -50%);
					width: 9px;
					height: 9px;
					background: $green;
					border-radius: 50%;
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.movePage {
		$this: &;

		&_inner {}

		&_list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}

		&_item {
			& > a {
				padding: 15px 20px;
			}

			&-prev {}

			&-list {}

			&-next {}
		}
	}
}
