// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

// topのボタンホバーで画像を切り替えるスタイル（会社案内）

.switch {
	$this: &;

	&_inner {
		display: grid;
		grid-template-columns: auto 1fr;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_images { // 画像を表示するエリア
		display: grid; // 画像を重ねる
		overflow: hidden;
		border-radius: 30px;
		box-shadow: 20px -20px 0px 0px $yellow-exLight;
	}

	// 画像非表示の設定
	&_img {
		grid-column: 1 / -1; // マイナスの値は右下からグリッド線を数える
		grid-row: 1 / -1;
		opacity: 0;
		visibility: hidden;
		// transform: translateY(-20px);
		transition:
			opacity .4s cubic-bezier(0.65, 0, 0.35, 1), // cubic-bezier ベジェ曲線
			visibility .4s cubic-bezier(0.65, 0, 0.35, 1);

		& > img {
			width: 680px;
			height: 684px;
			aspect-ratio: 680/684;
			object-fit: contain;
		}


		// 画像表示の設定
		&-isActive {
			opacity: 1;
			visibility: visible;
			transition:
				opacity .4s cubic-bezier(0.65, 0, 0.35, 1),
				visibility .4s cubic-bezier(0.65, 0, 0.35, 1);
		}
	}

	&_info {
		margin: 40px 0 0;
	}

	&_btns {
		margin: 33px 20px 0 0;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_btn {
		margin: 20px 0 0;
		transition: margin-right .25s cubic-bezier(0.65, 0, 0.35, 1); // hover時に必要な設定
		& > a {
			display: flex;
			align-items: center;
			color: inherit;
			padding: 30px 20px 30px 30px;
			background: #fff;
			font-size: rem(20px, pc);
			border-radius: 0 999px 999px 0;
			line-height: 1;
			text-decoration: none;
			transition: all .4s;
			// opacity: 1;
			z-index: 1; // beforeのための指定（文字を手前に表示）
			overflow: hidden; // beforeのための指定
			position: relative;
			&::before { // green背景の指定
				content: "";
				width: 120%; // 横いっぱいに表示
				height: 200%; // 縦いっぱいに表示
				position: absolute;
				top: -50%;
				right: 0;
				z-index: -1; // 背景を文字より嘘路に表示
				background: $green;
				border-radius: 0 100% 100% 0;
				transform: translateX(-100%); // 左側に隠す
				transition: transform ease .4s;
			}
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translate(0, -50%);
				width: 15px;
				height: 15px;
				background: $green;
				border-radius: 50%
			}
			& > span {
				@include font-lato();
				font-size: rem(30px, pc);
				margin-right: 40px;
			}
			@media (hover:hover) {
				&:hover {
					color: #fff;
					font-weight: bold;
					opacity: 1;
					&::before {
						transform: translateX(10%); // green背景の位置指定
					}
					&::after {
						background: #fff;
					}
				}
			}
		}

		@media (hover:hover) {
			&:hover {
				margin-right: -20px; // ボタンが右へ伸びる
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.switch {
		$this: &;
		margin: 45px 0 0;

		&_inner {
			display: block;
		}

		&_images {
			display: grid;
			grid-template-rows: auto;
			box-shadow: 10px -10px 0px 0px $yellow-exLight;
		}

		&_img {
			opacity: 1;
			visibility: visible;
			& > img {
				width: 100%;
				height: initial;
				aspect-ratio: 671/391;
				object-fit: contain;
			}

			&-isActive {}
		}

		&_info {
			margin: 15px 0 0;
		}

		&_btns {
			margin: 0;
		}

		&_btn {
			margin: 10px 0 0;
			& > a {
				padding: 18px 10px 16px 25px;
				font-size: rem(15px, sp);
				border-radius: 999px;
				&::before {
					display: none;
				}
				&::after {
					width: 10px;
					height: 10px;
					right: 10px;
				}
				& > span {
					margin-right: 20px;
					font-size: rem(24px, sp);
				}
				@media (hover:hover) {
					&:hover {
						color: initial;
						font-weight: initial;
						opacity: initial;
						&::before {
							transform: initial; // green背景の位置指定
						}
						&::after {
							background: $green;
						}
					}
				}
			}

			@media (hover:hover) {
				&:hover {
					margin-right: 0;
					//margin-right: -15px; // ボタンが右へ伸びる
				}
			}
		}
	}
}