// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine1

見出し2

Markup:
<h2 class="headLine1">見出し2</h2>

Styleguide #{$guideNum}
*/
.headLine1 {
	$this: &;
	margin: 40px 0 30px;
	padding: 0 0 10px;
	font-weight: bold;
	font-size: rem(24px, pc);
	line-height: 1.4;
	border-bottom: 4px dotted $txtColor;
	// background-image : linear-gradient(to right, $txtColor 3px, transparent 3px); // ドットの大きさと余白が始まる場所を指定
	// background-size: 10px 3px; // ドット+余白=10px, ドットの高さ3px
	// background-repeat: repeat-x;
	// background-position: left bottom;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine1 {
		$this: &;
	}
}
