// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.ceo {
	$this: &;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0 60px;

	&_left {}

	&_img {
		margin: 0;
		& > img {
			width: 100%;
			height: 100%;
			aspect-ratio: 400/400;
			object-fit: contain;
		}
	}

	&_body {}

	&_post {
		margin: 0;
		font-size: rem(18px, pc);
		@include line-height(30, 18);
	}

	&_name {
		margin: 10px 0 0;
		font-size: rem(30px, pc);
	}
}

@media screen and (max-width: $breakpoint-sp){
	.ceo {
		$this: &;
		flex-direction: column;
		gap: 30px 0;

		&_left {}

		&_img {}

		&_body {}

		&_post {}

		&_name {}
	}
}
