// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
qaNoAcc

アコーディオンの機能を消すためのパーツ
qaAccとスタイルは全く同じ
buttonタグをdivへ
toggle削除
hoverなし

Markup:
<div class="qaNoAcc">
	<ul class="qaNoAcc_list">
		<li class="qaNoAcc_item">
			<div class="qaNoAcc_btn">
				<p class="qaNoAcc_title">書籍はどこで購入できますか？</p><!-- /.qaNoAcc_title -->
				<p class="qaNoAcc_toggle"></p><!-- /.qaNoAcc_toggle -->
			</div><!-- /.qaNoAcc_btn -->
			<div class="qaNoAcc_content">
				<div class="qaNoAcc_inner">
					<p class="qaNoAcc_txt">内容が入ります</p><!-- /.qaNoAcc_txt -->
				</div><!-- /.qaNoAcc_inner -->
			</div><!-- /.qaNoAcc_content -->
		</li><!-- /.qaNoAcc_item -->
		<li class="qaNoAcc_item">
			<div class="qaNoAcc_btn">
				<p class="qaNoAcc_title">教科書を購入したいのですがどうすればいいですか？</p><!-- /.qaNoAcc_title -->
				<p class="qaNoAcc_toggle"></p><!-- /.qaNoAcc_toggle -->
			</div><!-- /.qaNoAcc_btn -->
			<div class="qaNoAcc_content">
				<div class="qaNoAcc_inner">
					<p class="qaNoAcc_txt">
						弊社では教科書準拠品を販売しておりますが、教科書の販売は行っておりません。<br>
						全国教科書供給協会HPより、各都道府県の教科書供給所の案内をご確認ください。<br>
						<a href="" class="qaNoAcc_link">http://www.text-kyoukyuu.or.jp/gaiyou.html#k3</a>
					</p><!-- /.qaNoAcc_txt -->
				</div><!-- /.qaNoAcc_inner -->
			</div><!-- /.qaNoAcc_content -->
		</li><!-- /.qaNoAcc_item -->
	</ul><!-- /.qaNoAcc_list -->
</div><!-- /.qaNoAcc -->

Styleguide #{$guideNum}
*/
.qaNoAcc {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		& > :first-child {
			margin-top: 0;
		}
	}

	&_item {
		margin: 20px 0 0;
		background: #fff;
		border-radius: 20px;
	}

	&_btn {
		padding: 15px 20px;
		display: grid;
		grid-template-columns: auto 15px;
		align-items: flex-start;
		gap: 0 20px;
		transition: all .4s;
	}

	&_title,
	&_txt {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 1px;
			left: 0;
			font-size: rem(30px, pc);
			font-weight: bold;
			@include font-cen();
			line-height: 1;
		}
	}

	&_title {
		margin: 0;
		padding-left: 45px;
		text-align: left;
		&::before {
			content: 'Q';
			color: $blue;
		}
	}

	&_content {}

	&_inner {
		padding: 15px 20px;
		border-top: 1px solid $gray02;
	}

	&_txt {
		margin: 0;
		padding-left: 45px;
		&::before {
			content: 'A';
			color: $pink;
		}
	}

	&_link {
		padding: 0 0 3px;
		color: inherit;
		// text-underline-offset: 9px;
		border-bottom: 1px solid #000; // 疑似要素との隙間にもborderが入る
		text-decoration: none;
		&:hover {
			border-bottom: solid 1px transparent; // text-underlineの時と同じ挙動
		}

		//  blank
		//----------------------------------------------------------------------
		&-blank {
			&::after {
				margin-left: 10px;
				@include icons($icons-blank);
				font-size: .81em;
				font-weight: normal;
				line-height: 1;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.qaNoAcc {
		$this: &;

		&_list {}

		&_item {}

		&_btn {}

		&_title,
		&_txt {
			padding-left: 40px;
			&::before {
				top: -1px;
			}
		}

		&_title {}

		&_content {}

		&_inner {}

		&_txt {}

		&_link {}
	}
}
