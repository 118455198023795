// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
pageHeader

見出し1
サイドバーがない時に使用
contentsの上で使用するもの

Markup:
<div class="pageHeader">
	<div class="pageHeader_inner">
		<h1 class="pageHeader_title">見出し1（.pageHeader）<br>サイドバーがない時に使用</h1><!-- /.pageHeader_title -->
	</div><!-- /.pageHeader_inner -->
</div><!-- /.pageHeader -->

Styleguide #{$guideNum}
*/
.pageHeader {
	$this: &;

	&_inner {
		@include content-width();
		padding: 57px 42px 57px 40px;
		background: #fff;
		border-radius: 30px;
	}

	&_title {
		padding-left: 40px;
		font-size: rem(30px, pc);
		font-weight: bold;
		@include line-height(46, 30);
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			width: 14px;
			height: 14px;
			background: $green;
			border-radius: 50%;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pageHeader {
		$this: &;

		&_inner {
			padding: 45px 30px 45px 30px;
		}

		&_title {
			padding-left: 30px;
			font-size: rem(25px, sp);
		}
	}
}
