// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
cates

cates

Markup:
<div class="cates">
	<ul class="cates_list">
	</ul><!-- /.cates_list -->
</div><!-- /.cates -->

Styleguide #{$guideNum}
*/
.cates {
	$this: &;

	&_list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	&_item {}

	&_link {
		text-decoration: none;
	}
}


@media screen and (max-width: $breakpoint-sp){
	.cates {
		$this: &;

		&_list {}

		&_item {}
	}
}
