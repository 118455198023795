// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


[data-acc-root] {}
[data-acc-btn] {}
[data-acc-content] {
	overflow: hidden;
	transition: height .4s;
	// visibility: hidden;
	height: 0;
}
[data-acc-content-snap] {
	transition: none !important;
}