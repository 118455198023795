// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.qaBtns {
	$this: &;
	margin: 20px 0 0;

	.btns_list {
		margin: 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 12px 15px;
	}

	.btn {
		padding: 13px 10px;
		font-size: rem(20px, pc);
	}
}

@media screen and (max-width: $breakpoint-sp){
	.qaBtns {
		$this: &;

		.btns_list {
			display: grid;
			grid-template-columns: 1fr;
			margin: 0 auto;
			max-width: 375px;
		}

		.btn {
			font-size: rem(16px, sp);
		}
	}
}
