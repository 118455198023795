// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
articleHeader

articleHeader

Markup:
<div class="articleHeader">
	<div class="articleHeader_inner">
		<p class="cate cate-s articleHeader_cate" style="color: #fff; background-color: #7fbf26;">一般の方向け</p><!-- /.articleHeader_cate -->
		<h1 class="articleHeader_title">明治とコラボしました。お菓子が出てくる楽しいドリル「おかしなドリル」</h1><!-- /.articleHeader_title -->
	</div><!-- /.articleHeader_inner -->
</div><!-- /.articleHeader -->

Styleguide #{$guideNum}
*/
.articleHeader {
	$this: &;

	&_inner {
		@include content-width();
		padding: 40px 40px 35px;
		background: #fff;
		border-radius: 30px;
	}

	&_cate {
		display: inline-block;
		line-height: 30px;
	}

	&_title {
		margin: 10px 0 0;
		font-weight: bold;
		font-size: rem(30px, pc);
		@include line-height(46, 30);
	}
}

@media screen and (max-width: $breakpoint-sp){
	.articleHeader {
		$this: &;

		&_inner {
			padding: 30px 30px 25px;
		}

		&_cate {
			margin: 5px 0 0;
		}

		&_title {
			font-size: rem(25px, sp);
		}
	}
}
