// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
bottomSns

bottomSns

Markup:
<div class="bottomSns">
	<div class="bottomSns_inner">
		<p class="bottomSns_txt">各種SNSも更新中！</p><!-- /.bottomSns_txt -->
		<ul class="bottomSns_list">
			<li class="bottomSns_item"><a href="#" target="_blank" rel="noopener noreferrer"><img src="/assets/images/global/youtube.png" alt="YouTube" loading="lazy">YouTube</a></li><!-- /.bottomSns_item -->
			<li class="bottomSns_item"><a href="#" target="_blank" rel="noopener noreferrer"><img src="/assets/images/global/x.png" alt="X" loading="lazy">X</a></li><!-- /.bottomSns_item -->
			<li class="bottomSns_item"><a href="#" target="_blank" rel="noopener noreferrer"><img src="/assets/images/global/instagram.png" alt="Instagram" loading="lazy">Instagram</a></li><!-- /.bottomSns_item -->
			<li class="bottomSns_item"><a href="#" target="_blank" rel="noopener noreferrer"><img src="/assets/images/global/line.png" alt="LINE" loading="lazy">LINE</a></li><!-- /.bottomSns_item -->
		</ul><!-- /.bottomSns_list -->
	</div><!-- /.bottomSns_inner -->
</div><!-- /.bottomSns -->

Styleguide #{$guideNum}
*/
.bottomSns {
	$this: &;
	margin: 0 0 -25px;
	background: $yellow-exLight;
	border-radius: 30px 30px 0 0;

	&_inner {
		padding: 25px 0 48px;
		@include content-width();
		display: grid;
		grid-template-columns: 216px 1fr;
		gap: 0 90px;
	}

	&_txt {
		margin: 0;
		font-size: rem(24px, pc);
		font-weight: bold;
	}

	&_list {
		margin: 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0 10px;
	}

	&_item {
		& > a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 20px;
			padding: 6px 20px 8px 10px;
			min-height: 51px; // LINEだけ高さが違う
			color: inherit;
			background: #fff;
			@include font-zen();
			font-weight: bold;
			text-decoration: none;
			border-radius: 25px;
			word-break: break-all;
			&::after {
				@include icons($icons-blank);
				font-size: .5em;
				line-height: 1;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.bottomSns {
		$this: &;

		&_inner {
			padding: 20px 0 55px;
			display: flex;
			flex-direction: column;
		}

		&_txt {
			font-size: rem(18px, sp);
			text-align: center;
		}

		&_list {
			margin: 15px 0 0;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
		}

		&_item {
			& > a {
				padding: 6px 15px 5px 8px;
				font-size: rem(12px, sp);
				&::after {
					font-size: .7em;
				}
			}
		}
	}
}
