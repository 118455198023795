// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
grid

グリッド

grid_col-分子-分母（12/12まで）

Markup:
<div class="grid {$modifiers}">
	<div class="grid_col grid_col-4-12pc grid_col-6-12sp">PC:4/12  SP:6/12</div><!-- /.grid_col -->
	<div class="grid_col grid_col-4-12pc grid_col-6-12sp">PC:4/12  SP:6/12</div><!-- /.grid_col -->
	<div class="grid_col grid_col-4-12pc grid_col-12-12sp">PC:4/12  SP:12/12</div><!-- /.grid_col -->
	<div class="grid_col grid_col-3-12">PC:3/12  SP:3/12</div><!-- /.grid_col -->
	<div class="grid_col grid_col-3-12">PC:3/12  SP:3/12</div><!-- /.grid_col -->
	<div class="grid_col grid_col-3-12">PC:3/12  SP:3/12</div><!-- /.grid_col -->
</div><!-- /.grid -->

.btns-left - 左寄せ
.btns-center - 中央寄せ
.btns-right - 右寄せ
.btns-vat - 上揃え
.btns-vam - 上下中央揃え
.btns-vab - 下揃え

Styleguide #{$guideNum}
*/
.grid {
	$this: &;
	$gap: 20px;
	margin: 20px 0 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: var(--row-gap, #{$gap}) var(--column-gap, #{$gap});
	&-left { justify-content: flex-start; }
	&-center { justify-content: center; }
	&-right { justify-content: flex-end; }
	&-vat { align-items: flex-start; }
	&-vam { align-items: center; }
	&-vab { align-items: flex-end; }
	&_col {
		& > :first-child {
			margin-top: 0;
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				width: calc( (100% + var(--column-gap, #{$gap})) / 12 * #{$i} - var(--column-gap, #{$gap}) );
			}
		}
		@for $i from 1 to 11 {
			&-#{$i}-10 {
				width: calc( (100% + var(--column-gap, #{$gap})) / 10 * #{$i} - var(--column-gap, #{$gap}) );
			}
		}
	}
}
@media screen and (min-width: $breakpoint-sp + 1){
	.grid {
		$this: &;
		$gap: 20px;
		gap: var(--row-gap-pc, #{$gap}) var(--column-gap-pc, #{$gap});
		&-leftPc { justify-content: flex-start; }
		&-centerPc { justify-content: center; }
		&-rightPc { justify-content: flex-end; }
		&-vatPc { align-items: flex-start; }
		&-vamPc { align-items: center; }
		&-vabPc { align-items: flex-end; }
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					width: calc( (100% + var(--column-gap-pc, #{$gap})) / 12 * #{$i} - var(--column-gap-pc, #{$gap}) );
				}
			}
			@for $i from 1 to 11 {
				&-#{$i}-10pc {
					width: calc( (100% + var(--column-gap-pc, #{$gap})) / 10 * #{$i} - var(--column-gap-pc, #{$gap}) );
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.grid {
		$this: &;
		$gap: 20px;
		gap: var(--row-gap-sp, #{$gap}) var(--column-gap-sp, #{$gap});
		&-leftSp { justify-content: flex-start; }
		&-centerSp { justify-content: center; }
		&-rightSp { justify-content: flex-end; }
		&-vatSp { align-items: flex-start; }
		&-vamSp { align-items: center; }
		&-vabSp { align-items: flex-end; }
		&_col {
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					width: calc( (100% + var(--column-gap-sp, #{$gap})) / 12 * #{$i} - var(--column-gap-sp, #{$gap}) );
				}
			}
			@for $i from 1 to 11 {
				&-#{$i}-10sp {
					width: calc( (100% + var(--column-gap-sp, #{$gap})) / 10 * #{$i} - var(--column-gap-sp, #{$gap}) );
				}
			}
		}
	}
}