// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
cards

cards

Markup:
<div class="cards">
	<ul class="cards_list">
		<li class="cards_item">
			<a href="#" class="cards_link">
				<figure class="cards_img"><img src="/assets/images/top/attempt-img.png" alt="ダミー画像" loading="lazy"></figure><!-- /.cards_img -->
				<div class="cards_body">
					<p class="cate cards_cate" style="color: #fff; background-color: #7fbf26;">一般の方向け</p><!-- /.cards_cate -->
					<p class="cards_title">明治とコラボしました。お菓子が出てくる楽しいドリル「おかしなドリル」</p><!-- /.cards_title -->
				</div><!-- /.cards_body -->
			</a><!-- /.cards_link -->
		</li><!-- /.cards_item -->
		<li class="cards_item">
			<a href="#" class="cards_link">
				<figure class="cards_img"><img src="/assets/images/top/attempt-img.png" alt="ダミー画像" loading="lazy"></figure><!-- /.cards_img -->
				<div class="cards_body">
					<p class="cate cards_cate" style="color: #fff; background-color: #00a7ea;">会社の取り組み</p><!-- /.cards_cate -->
					<p class="cards_title">明治とコラボしました。お菓子が出てくる楽しいドリル「おかしなドリル」</p><!-- /.cards_title -->
				</div><!-- /.cards_body -->
			</a><!-- /.cards_link -->
		</li><!-- /.cards_item -->
		<li class="cards_item">
			<a href="#" class="cards_link">
				<figure class="cards_img"><img src="/assets/images/top/attempt-img.png" alt="ダミー画像" loading="lazy"></figure><!-- /.cards_img -->
				<div class="cards_body">
					<p class="cate cards_cate" style="color: #fff; background-color: #e62e8b;">その他</p><!-- /.cards_cate -->
					<p class="cards_title">明治とコラボしました。お菓子が出てくる楽しいドリル「おかしなドリル」</p><!-- /.cards_title -->
				</div><!-- /.cards_body -->
			</a><!-- /.cards_link -->
		</li><!-- /.cards_item -->
	</ul><!-- /.cards_list -->
</div><!-- /.cards -->

Styleguide #{$guideNum}
*/
.cards {
	$this: &;
	// margin: 40px 0 0;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		display: grid;

		//  column
		//----------------------------------------------------------------------
		&-3col {
			grid-template-columns: repeat(3, 1fr);
			gap: 40px 38px;
		}

		&-4col {
			grid-template-columns: repeat(4, 1fr);
			gap: 0 40px;
			.cards_title {
				font-size: rem(14px, pc);
			}
		}
	}

	&_item {
		background: #fff;
		border-radius: 30px;
	}

	&_link {
		display: block;
		text-decoration: none;
		@media (hover:hover) {
			&:hover {
				opacity: 1;
				.cards_title {
					color: $green;
				}
				.cards_body {
					&::after {
						opacity: 1;
					}
				}
			}
		}
	}

	&_img {
		margin: 0;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		overflow: hidden;
		& > img {
			width: 100%;
			height: 100%;
			aspect-ratio: 375/281;
			object-fit: contain;
		}
	}

	&_body {
		position: relative;
		z-index: 1;
		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 50%;
		}
		&::before {
			background: $green;
			z-index: 3;
		}
		&::after {
			background: $green-light;
			opacity: 0;
			z-index: 2;
			transition: all .4s;
		}
	}

	&_cate {
		display: inline-block;
	}

	&_title {
		margin: 12px 0 0;
		color: $txtColor;
		font-weight: bold;
		transition: all .4s;
		// ...を表示させるための指定
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 制限したい行数が3の場合 */
		overflow: hidden;
		// ...を表示させるための指定
	}
}

@media screen and (min-width: $breakpoint-sp + 1){
	.cards {
		$this: &;

		&_list {

			//  column
			//----------------------------------------------------------------------
			&-3col {
				.cards_body {
					height: 170px;
					padding: 20px;
					&::before {
						width: 15px;
						height: 15px;
						bottom: 20px;
						right: 20px;
					}
					&::after {
						width: 30px;
						height: 30px;
						bottom: 12px;
						right: 12px;
					}
				}
			}

			&-4col {
				.cards_body {
					height: 150px;
					padding: 14px;
					&::before {
						width: 10px;
						height: 10px;
						bottom: 17px;
						right: 17px;
					}
					&::after {
						width: 20px;
						height: 20px;
						bottom: 12px;
						right: 12px;
					}
				}
			}

		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.cards {
		$this: &;
		// margin: 0 10px;

		&_list {
			grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

			//  column
			//----------------------------------------------------------------------
			&-3col,
			&-4col {
				gap: 20px;
			}
		}

		&_item {
			display: flex;
			flex-direction: column;
			height: auto;
		}

		&_link {
			flex-grow: 1;
			height: 100%;
		}

		&_img {}

		&_body {
			padding: 14px;
			&::before {
				width: 10px;
				height: 10px;
				bottom: 14px;
				right: 14px;
			}
			&::after {
				width: 20px;
				height: 20px;
				bottom: 6px;
				right: 6px;
			}
		}

		&_cate {}

		&_title {
			margin: 10px 0 0;
			font-size: rem(13px, sp);
		}
	}
}

@media screen and (min-width: 560px){
	.cards {
		$this: &;

		&_list {

			//  column
			//----------------------------------------------------------------------
			&-3col,
			&-4col {
				.cards_body {
					min-height: 132px;
				}
			}
		}
	}
}
