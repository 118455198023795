// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
pankuzu

パンクズ

Markup:
<nav class="pankuzu">
	<div class="pankuzu_inner">
		<ul class="pankuzu_list">
			<li class="pankuzu_item"><a href="/">トップ</a></li>
			<li class="pankuzu_item"><a href="./">カテゴリタイトル</a></li>
			<li class="pankuzu_item">ページタイトル</li>
		</ul>
	</div>
</nav><!-- /.pankuzu -->

Styleguide #{$guideNum}
*/
.pankuzu {
	$this: &;
	// margin: 20px 0 0;
	margin: 0 0 35px;
	& > :first-child {
		margin-top: 0;
	}
	&_inner {
		width: $page-width;
		margin: 0 auto;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_list {
		margin: 0;
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
		& > :first-child {
			&:before {
				content: none;
			}
		}
	}
	&_item {
		color: $txtColor02;
		font-size: rem(12px, pc);
		& > a {
			color: $txtColor02;
		}
		&:before {
			content: '　>　';
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pankuzu {
		$this: &;
		margin: 0 0 25px;
		&_inner {
			margin: 0 10px;
			width: auto;
			overflow-x: auto;
			// overflow-x: hidden;
		}
		&_list {
			flex-wrap: nowrap;
		}
		&_item {
			font-size: rem(11px, sp);
			white-space: nowrap;
		}
	}
}
