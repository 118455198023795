@import './_config';

//mqpackerの順番制御用
@media screen and (hover: hover) { .mqDummy {width: auto;} }
@media screen and (min-width: $breakpoint-sp + 1) { .mqDummy {width: auto;} }
@media screen and (min-width: $breakpoint-sp) { .mqDummy {width: auto;} }
@media screen and (max-width: $breakpoint-sp) { .mqDummy {width: auto;} }

//
@import './_iconFonts';
// @import './_iconFontsClass';
@import './_svgSprite';
@import './_imgSprite';

// font-awesome使うなら以下必要
// @import './_font-awesome/_solid';
// @import './_font-awesome/_regular';
// @import './_font-awesome/_brands';
// @import './_font-awesome/_fontawesome';


//default style
@import "_default";

/*
便利クラス

マージン強制とかの便利クラス

Styleguide 1.
*/
$guideNum: '1.1'; @import './_shared/_responsiveHidden';
$guideNum: '1.2'; @import './_shared/_super';



/*
グローバル

gNavとかgHeaderとか
大まかなレイアウトもここに

Styleguide 3.
*/
$guideNum: '3.1'; @import './_global/_layout';
$guideNum: '3.2'; @import './_global/_overlay';
$guideNum: '3.3'; @import './_global/_gHeadArea';
$guideNum: '3.4'; @import './_global/_gHeader';
$guideNum: '3.5'; @import './_global/_gFootArea';
$guideNum: '3.6'; @import './_global/_bottomSns';
$guideNum: '3.7'; @import './_global/_gFooter';
$guideNum: '3.8'; @import './_global/_toTop';


/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 4.
*/
$guideNum: '4.1'; @import './_parts/_font';
$guideNum: '4.2'; @import './_parts/_color';
$guideNum: '4.3'; @import './_parts/_iconTxt';
$guideNum: '4.4'; @import './_parts/_btn';
$guideNum: '4.5'; @import './_parts/_btns';
$guideNum: '4.6.2'; @import './_parts/_headLine1';
$guideNum: '4.6.3'; @import './_parts/_headLine2';
$guideNum: '4.6.4'; @import './_parts/_headLine3';
$guideNum: '4.6.5'; @import './_parts/_headLine4';
$guideNum: '4.7'; @import './_parts/_customInput';
$guideNum: '4.8'; @import './_parts/_responsiveTable';
$guideNum: '4.9'; @import './_parts/_scrollTable';
$guideNum: '4.10'; @import './_parts/_dataThTable';
$guideNum: '4.11'; @import './_parts/_youtube';
$guideNum: '4.12'; @import './_parts/_cate';
$guideNum: '4.13'; @import './_parts/_cates';


/*
モジュール

その他モジュール

Styleguide 5.
*/
$guideNum: '5.1'; @import './_modules/_pankuzu';
$guideNum: '5.2'; @import './_modules/_pager';
$guideNum: '5.3'; @import './_modules/_imgTxt';
$guideNum: '5.4'; @import './_modules/_grid';
// $guideNum: '5.5'; @import './_modules/_form';
// $guideNum: '5.6'; @import './_modules/_fileForm';
$guideNum: '5.7.1'; @import './_modules/_treeMenu';
// $guideNum: '5.7.2'; @import './_modules/_treeMenu2';
$guideNum: '5.8'; @import './_modules/_pages';
$guideNum: '5.9'; @import './_modules/_panel';
$guideNum: '5.10'; @import './_modules/_movePage';
$guideNum: '5.11'; @import './_modules/_newsHeader';
// $guideNum: '5.12'; @import './_modules/_cateSide';
$guideNum: '5.13'; @import './_modules/_floatingCookie';
$guideNum: '5.14'; @import './_modules/_cards';
$guideNum: '5.15'; @import './_modules/_articleHeader';
$guideNum: '5.16'; @import './_modules/_pageHeader';
$guideNum: '5.17'; @import './_modules/_pageHeaderSide';
$guideNum: '5.18'; @import './_modules/_corporateCards';
$guideNum: '5.19'; @import './_modules/_qaBtns';
$guideNum: '5.20'; @import './_modules/_qaPanel';
$guideNum: '5.21'; @import './_modules/_qaTab';
$guideNum: '5.22'; @import './_modules/_qaAcc';
$guideNum: '5.23'; @import './_modules/_qaNoAcc';
$guideNum: '5.24'; @import './_modules/_switch';
$guideNum: '5.25'; @import './_modules/_chronology';
$guideNum: '5.26'; @import './_modules/_roundedTitles';
$guideNum: '5.27'; @import './_modules/_ceo';
$guideNum: '5.28'; @import './_modules/_naruhodoTitle';


/*
サイド

サイド用のクラス

Styleguide 6.
*/
$guideNum: '6.1'; @import './_side/_sideFrame';
$guideNum: '6.2'; @import './_side/_cateSide';

//============================================================
//  pages
//============================================================
@import './pages/_top';


//============================================================
//  plugins
//============================================================
@import './_plugins/_swiper-custom';


//============================================================
// js
//
// js関連の最低限のスタイル　基本いじらない
// 使い方はフロント開発テンプレートDoc参照
//============================================================
// @import './_js/_customDialog';
// @import './_js/_contentsModal';
@import './_js/_accordion';
@import './_js/_tab';
@import './_js/_tree';
// @import './_js/_objectFitPolyfil';
// @import './_js/_snackbar';
// @import './_js/_validator';



//editor用デフォルトテンプレートscss
@import './_editorTmpl/_index';