//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 736px;
$breakpoint-scroll: 1200px;

//  size
//----------------------------------------------------------------------
$psd-width: 1900px;
$page-width: 1200px; //仮
$main-width: 960px; //仮
$side-width: 210px; //仮
$fullWidthCalc: min((var(--vw, 1vw) * 100 - #{$page-width}) / -2, -20px);

$font-size: (
	'pc': 16px, //仮
	'sp': 14px, //仮
) !default;
$lineHeight: calc(30/16);

//  colors
//----------------------------------------------------------------------

$txtColor: #333;
$txtColor02: #626262;
$txtColor03: #4f4f4c;

$overlayBg: rgba(#000, .8); //仮

$red: #f43838;
$red-light: lighten($red, 10%); //仮

$pink: #e62e8b;

$yellow-light: #f8f7e7;
$yellow-exLight: #eeedd8;
$yellow-dark: #c7c6ad;

$green: #7fbf26;
$green-light: #d8ecbd;
$green-exLight: #e9f0de;

$blue: #00a7ea;
$blue-light: lighten($blue, 10%); //仮

$gray: #eee;
$gray02: #e5e5e5;
$gray03: #dcdcdc;
$gray-light: lighten($gray, 10%); //仮
$gray-exLight: lighten($gray, 43%); //仮
$gray-border: $gray-light; //仮
$gray-bg: $gray-exLight; //仮

$black: #222; //仮
$black02: #383838;
$black03: #333;


//  font
//----------------------------------------------------------------------
$fa-font-path: "/assets/fonts/font-awesome";
$fa-font-size-base: map-get($font-size, 'pc');
$fa-fw-width: calc(20em / 16);

@mixin far($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fa($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fas($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fab($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Brands';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}

@mixin grade($from, $to, $vertical: false) {
	@if $vertical {
		background: $from;
		background: -moz-linear-gradient(top, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(top, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to bottom, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=0 );
	} @else {
		background: $from;
		background: -moz-linear-gradient(left, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(left, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to right, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=1 );
	}
}

//px to rem
@function rem($px, $key: 'pc') {
	$value: map-get($font-size, $key);
	@return calc(($px / $value) * 1rem);
}

//======================================================================
//  コンテンツ幅
//======================================================================
@mixin content-width {
	margin: 0 auto;
	max-width: $page-width;
	@media screen and (max-width: $breakpoint-sp){
		margin: 0 10px;
	}
}
// @mixin -width {
// }

// 記述
// @include content-width();

//======================================================================
//  z-index
//======================================================================
$z-map: (
	floatingCookie: 5000,
	gHeader_logo: 4000,
	gHeader_navBtn: 4000,
	gHeader_nav: 3000,
	gHeadArea: 2000,
	toTop: 1000,
);
@function z($name) {
	@return map-get($z-map, $name);
}

// 記述
// z-index: z(menuBtn);

//======================================================================
//  タイトル
//======================================================================
// @mixin pageTitle {
// 	color: $text-green02;
// 	font-weight: bold;
// 	font-size: rem(48px, pc);

// 	@media screen and (max-width: $breakpoint-sp){
// 		font-size: rem(40px, sp);
// 	}
// }

//======================================================================
//  font-weight
//======================================================================
.fBold {
	font-weight: 700;
}

//======================================================================
//  font-size（調整中）
//======================================================================
// .font-s {
// 	font-size: rem(20px, pc);
// }

// .font-20 {
// 	font-size: rem(20px, pc);
// }

//======================================================================
//  font-family
//======================================================================
@mixin font-noto {
	font-family: 'Noto Sans JP', sans-serif;
}
@mixin font-lato {
	font-family: 'Lato', sans-serif;
}
@mixin font-zen {
	font-family: 'Zen Kaku Gothic New', sans-serif;
}
@mixin font-cen {
	font-family: 'Century Gothic', sans-serif;
}
// @mixin font- {
// 	font-family: ;
// }

// 記述
// @include font-noto();

//======================================================================
//  line-height
//======================================================================
@mixin line-height($height, $font-size) {
	line-height: calc($height / $font-size);
}

// 記述
// @include line-height(28, 16);

// 説明
// @include line-height(行送り, フォントサイズ);

//======================================================================
//  letter-spacing
//======================================================================
@mixin letter-spacing($tracking) {
	letter-spacing: calc($tracking / 1000) + em;
}

// 記述
// @include letter-spacing(100);

// 説明
// @include letter-spacing(トラッキング値);

//======================================================================
//  border-radius
//======================================================================
// @mixin radius-normal {
// 	border-radius: 30px;
// }
// @mixin radius-half {
// 	border-radius: 999px;
// }
// @mixin radius-circle {
// 	border-radius: 50%;
// }

// 記述
// @include radius-circle();

//======================================================================
//  box-shadow
//======================================================================
// @mixin box-shadow01 {
// 	box-shadow: ;
// }

// 記述
// @include box-shadow01();
