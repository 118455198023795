// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
treeMenu

ツリーメニュー

Markup:
<div class="treeMenu" data-tree>
	<ul class="treeMenu_list" data-tree-list>
		<li class="treeMenu_item" data-tree-item>
			<div class="treeMenu_itemHead" data-tree-head>
				<a href="" class="treeMenu_name">第一階層</a>
				<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
			</div>
			<ul class="treeMenu_list" data-tree-list>
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
						<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
					</div>
					<ul class="treeMenu_list" data-tree-list>
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
					</ul><!-- /.tree_list -->
				</li><!-- /.tree_item -->
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
						<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
					</div>
					<ul class="treeMenu_list" data-tree-list>
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
					</ul><!-- /.tree_list -->
				</li><!-- /.tree_item -->
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
					</div>
				</li><!-- /.tree_item -->
			</ul><!-- /.tree_list -->
		</li><!-- /.tree_item -->
		<li class="treeMenu_item" data-tree-item>
			<div class="treeMenu_itemHead" data-tree-head>
				<a href="" class="treeMenu_name">第一階層</a>
				<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
			</div>
			<ul class="treeMenu_list" data-tree-list>
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
						<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
					</div>
					<ul class="treeMenu_list" data-tree-list>
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name" data-tree-current>第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
					</ul><!-- /.tree_list -->
				</li><!-- /.tree_item -->
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
						<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
					</div>
					<ul class="treeMenu_list" data-tree-list>
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
					</ul><!-- /.tree_list -->
				</li><!-- /.tree_item -->
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
					</div>
				</li><!-- /.tree_item -->
			</ul><!-- /.tree_list -->
		</li><!-- /.tree_item -->
		<li class="treeMenu_item" data-tree-item>
			<div class="treeMenu_itemHead" data-tree-head>
				<a href="" class="treeMenu_name">第一階層</a>
				<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
			</div>
			<ul class="treeMenu_list" data-tree-list>
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
						<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
					</div>
					<ul class="treeMenu_list" data-tree-list>
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
					</ul><!-- /.tree_list -->
				</li><!-- /.tree_item -->
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
						<button type="button" class="treeMenu_toggle" data-tree-toggle></button>
					</div>
					<ul class="treeMenu_list" data-tree-list>
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
						<li class="treeMenu_item" data-tree-item>
							<div class="treeMenu_itemHead" data-tree-head>
								<a href="" class="treeMenu_name">第三階層</a>
							</div>
						</li><!-- /.tree_item -->
					</ul><!-- /.tree_list -->
				</li><!-- /.tree_item -->
				<li class="treeMenu_item" data-tree-item>
					<div class="treeMenu_itemHead" data-tree-head>
						<a href="" class="treeMenu_name">第二階層</a>
					</div>
				</li><!-- /.tree_item -->
			</ul><!-- /.tree_list -->
		</li><!-- /.tree_item -->
	</ul><!-- /.tree_list -->
</div><!-- /.tree -->

Styleguide #{$guideNum}
*/

.treeMenu {
	$this: &;
	&_list {
		margin: 0 0 0 14px;
	}
	&_item {
	}
	&_itemHead {
		display: flex;
		display: -webkit-flex;
		// align-items: center;
	}
	&_check {
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0 auto 0 0;
		padding: 5px 0;
		& > .customInput {
			width: 100%;
		}
	}
	&_name {
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0 auto 0 0;
		// padding: 5px 0 5px 14px;
		padding: 2px 0 2px 13px;
		color: inherit;
		font-size: rem(14px, pc);
		// text-indent: -14px;
		text-decoration: none;
		position: relative;
		&:before {
			@include icons($icons-caret-right);
			font-size: .5em;
			font-weight: bold;
			position: absolute;
			// top: 50%;
			top: 9px;
			left: 0;
			// transform: translate(0, -50%);
			// content: '';
			// display: inline-block;
			// vertical-align: middle;
			// letter-spacing: normal;
			// width: 0;
			// height: 0;
			// border-style: solid;
			// border-width: 3px 0 3px 6px;
			// border-color: transparent transparent transparent $black;
			// margin-right: 8px;
		}
		&[data-tree-current] {
			color: $green;
			font-weight: bold;
		}
	}
	&_toggle {
		flex-grow: 0;
		flex-shrink: 0;
		margin: 10px 0 0 10px;
		width: 9px;
		height: 9px;
		// border-radius: 4px;
		// background: red;
		position: relative;
		&:before,
		&:after {
			content: '';
			width: 9px;
			height: 1px;
			background: $black;
			margin: -1px 0 0 -4px;
			position: absolute;
			top: 50%;
			left: 50%;
			transition: all .4s;
		}
		&:after {
			transform: rotate(90deg);
		}
		&[data-tree-toggle="open"] {
			&:after {
				transform: rotate(0);
				// opacity: 0;
			}
		}
	}

	//  第2階層
	//----------------------------------------------------------------------
	& > #{$this}_list {
		margin-left: 0;
		& > #{$this}_item {
			margin: 20px 0 0;
			& > #{$this}_itemHead {
				& > #{$this}_name {
					padding-left: 0;
					font-weight: bold;
					&::before {
						content: none;
					}
				}
				& > #{$this}_toggle {}
			}

			//  第3階層
			//----------------------------------------------------------------------
			& > #{$this}_list {
				margin-left: 0;
				& > #{$this}_item{
					& > #{$this}_itemHead{
						& > #{$this}_name{}
					}
					& > #{$this}_toggle {}
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.treeMenu {
		$this: &;
		&_list {
		}
		&_item {
		}
		&_itemHead {
		}
		&_name {
			padding: 3px 13px 3px;
		}
		&_toggle {
		}
	}
}