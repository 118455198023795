// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
gFootArea

gFootArea

Markup:
{modifiers}

Styleguide #{$guideNum}
*/
.gFootArea {
	$this: &;
	position: sticky;
	top: 100vh;
}

@media screen and (max-width: $breakpoint-sp){
	.gFootArea {
		$this: &;
	}
}
