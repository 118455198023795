// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
pages

pages

Markup:
<div class="pages">
	<ul class="pages_list">
		<li class="pages_item">
			<p class="pages_date">2024年1月25日</p><!-- /.pages_date -->
			<p class="cate cate-s pages_cate" style="color: #7fbf26; border-color: #7fbf26;">カテゴリ</p><!-- /.pages_cate -->
			<p class="pages_txt"><a href="">サーバーメンテナンスのお知らせ</a></p><!-- /.pages_txt -->
		</li><!-- /.pages_item -->
		<li class="pages_item">
			<p class="pages_date">2023年12月26日</p><!-- /.pages_date -->
			<p class="cate cate-s pages_cate" style="color: #00a7ea; border-color: #00a7ea;">カテゴリ</p><!-- /.pages_cate -->
			<p class="pages_txt"><span>種別[なし]の場合：表示</span></p><!-- /.pages_txt -->
		</li><!-- /.pages_item -->
		<li class="pages_item">
			<p class="pages_date">2023年10月5日</p><!-- /.pages_date -->
			<p class="cate cate-s pages_cate" style="color: #e62e8b; border-color: #e62e8b;">カテゴリ</p><!-- /.pages_cate -->
			<p class="pages_txt"><a href="" rel="nofollow">【News：基本情報】「nofollow：ON」</a></p><!-- /.pages_txt -->
		</li><!-- /.pages_item -->
	</ul><!-- /.pages_list -->
</div><!-- /.pages -->

Styleguide #{$guideNum}
*/
.pages {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		& > :first-child {
			padding-top: 0;
		}
	}

	&_item {
		display: grid;
		grid-template-columns: 120px 130px auto;
		align-items: center;
		gap: 0 30px;
		padding: 14px 0 15px;
		color: $txtColor;
		border-bottom: 1px solid $yellow-dark;
	}

	&_date {
		margin: 0;
	}

	&_cate {}

	&_txt {
		margin: 0;
		& > a {
			display: block;
			color: inherit;
			text-decoration: none;
			transition: all .4s;
			@media (hover:hover) {
				&:hover {
					color: $green;
					font-weight: bold;
					opacity: 1;
				}
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pages {
		$this: &;

		&_list {}

		&_item {
			grid-template-columns: 120px auto 1fr;
			gap: 0 10px;
			padding: 15px 0 10px;
		}

		&_date {
			grid-row: 1;
			grid-column: 1 / 3;
			font-size: rem(16px, sp);
		}

		&_cate {
			grid-row: 1;
			grid-column: 2 / 3;
			font-size: rem(12px, sp);
			min-width: 130px;
			min-height: 30px;
		}

		&_txt {
			grid-row: 2;
			grid-column: 1 / 4;
			margin: 5px 0 0;
			& > a {
				font-size: rem(16px, sp);
			}
		}
	}
}
