// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine3

見出し4

Markup:
<h4 class="headLine3">見出し4</h4>

Styleguide #{$guideNum}
*/
.headLine3 {
	$this: &;
	margin: 30px 0 20px;
	color: $blue;
	font-weight: bold;
	font-size: rem(20px, pc);
	line-height: 1.4;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine3 {
		$this: &;
	}
}
