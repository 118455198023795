@import '../_config';
@import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

//============================================================
//  pageID-top
//============================================================
.pageID-top {
//----------------------------------------------------------------------

//  top専用スタイル
//----------------------------------------------------------------------
.contents {
	margin: 0 auto 60px;
}
@media screen and (max-width: $breakpoint-sp){
	.contents {
		margin: 0 auto 40px;
	}
}

.topTitle {
	font-size: rem(36px, pc);
	font-weight: bold;
	line-height: 1;
}

//  mainVisual
//----------------------------------------------------------------------
.mainVisual {
	$this: &;

	&_inner {
		margin: 0 auto;
		padding: 0 20px;
		max-width: $psd-width;
	}

	&_images {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_img {
		margin-top: 0;
		& > img {
			object-fit: contain;
		}

		&-txt {
			order: 1;
			// flex-shrink: 0;
			// max-width: 683px;
			min-width: 600px;
			margin-right: -175px;
		}

		&-girl {
			order: 2;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.mainVisual {
		$this: &;

		&_inner {
			padding: 0;
		}

		&_images {
			display: block;
		}

		&_img {

			&-txt {
				flex-shrink: initial;
				margin: 30px 0 0;
				min-width: initial;
			}

			&-girl {}
		}
	}
}

//  topHope
//----------------------------------------------------------------------
.topHope {
	$this: &;
	margin: 70px 40px 0;
	overflow-x: hidden;

	&_inner {
		margin: 0 auto;
		max-width: $psd-width;
	}

	&_contents {
		display: grid;
		grid-template-columns: auto 720px auto;
		justify-content: center;
		gap: 0 50px;
	}

	&_images {
		position: relative;
	}

	&_img {
		margin: 0;
		width: 502px;
		opacity: 0;
		visibility: hidden;
		transition: all 1s;
		& > img {
			aspect-ratio: 502/520;
			object-fit: contain;
		}

		&-left,
		&-right {
			// position: relative;
			// &::before,
			// &:after {
			// 	content: '';
			// 	display: inline-block;
			// 	position: absolute;
			// 	background-size: contain;
			// 	background-repeat: no-repeat;
			// 	background-position: center;
			// }
		}

		&-left {
			transform: translateX(-50px);
			// &::before {
			// 	width: 60px;
			// 	height: 99px;
			// 	background-image: url(/assets/images/top/hope-balloon-pink.png);
			// 	top: 136px;
			// 	right: -30px;
			// }
			// &::after {
			// 	width: 47px;
			// 	height: 77px;
			// 	background-image: url(/assets/images/top/hope-balloon-green.png);
			// 	bottom: 84px;
			// 	right: 53px;
			// }
		}

		&-right {
			transform: translateX(50px);
			// &::before {
			// 	width: 42px;
			// 	height: 70px;
			// 	background-image: url(/assets/images/top/hope-balloon-blue.png);
			// 	bottom: 55px;
			// 	left: -20px;
			// }
		}

		&-isActive {
			opacity: 1;
			visibility: visible;
			transform: translateX(0); // 元の位置に戻す
		}
	}

	&_imagesInner {}

	&_balloon {
		margin: 0;
		opacity: 0;
		visibility: hidden;
		transition: all 1.5s ease;
		position: absolute;
		bottom: 0;

		&-pink,
		&-green,
		&-blue {
			&-isActive {
				opacity: 1;
				visibility: visible;
			}
		}

		&-pink {
			&-isActive {
				bottom: 285px;
				right: -30px;
			}
		}

		&-green {
			&-isActive {
				bottom: 84px;
				right: 53px;
			}
		}

		&-blue {
			&-isActive {
				bottom: 55px;
				left: -20px;
			}
		}

	}

	&_info {}

	&_title {}

	&_txt {
		margin: 30px 0 0;
		font-size: rem(18px, pc);
		@include line-height(40, 18);
		text-align: center;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topHope {
		$this: &;
		margin: 45px 0 0;
		// overflow-x: initial;
		overflow: hidden;

		&_inner {
			margin: 0 10px;
		}

		&_contents {
			display: block;
		}

		&_images {}

		&_img {
			width: initial;
			& > img {
				width: 100%;
				height: 100%;
			}

			&-left {
				// &::before {
				// 	width: 30px;
				// 	height: 49px;
				// 	background-image: url(/assets/images/top/hope-balloon-blue.png);
				// 	top: initial;
				// 	right: initial;
				// 	left: 37px;
				// 	bottom: -28px;
				// }
				// &::after {
				// 	width: 42px;
				// 	height: 69px;
				// 	background-image: url(/assets/images/top/hope-balloon-pink.png);
				// 	position: absolute;
				// 	bottom: -2px;
				// 	right: 17px;
				// }
			}

			&-right {
				margin: 40px 0 0;
				// &::before {
				// 	width: 33px;
				// 	height: 53px;
				// 	background-image: url(/assets/images/top/hope-balloon-green.png);
				// 	top: 5px;
				// 	left: 23px;
				// 	right: initial;
				// }
			}
		}

		&_imagesInner {}

		&_balloon {
			bottom: -50px;

			&-pink {
				width: 42px;
				height: 69px;
				&-isActive {
					bottom: -2px;
					right: 17px;
				}
			}

			&-green {
				width: 33px;
				height: 53px;
				&-isActive {
					// top: 5px;
					// bottom: 473px;
					// bottom: 268px;
					// bottom: 310px;
					bottom: 83%;
					left: 23px;
					right: initial;
				}
			}

			&-blue {
				width: 30px;
				height: 49px;
				&-isActive {
					right: initial;
					left: 37px;
					bottom: -28px;
				}
			}
		}

		&_info {
			margin: 45px 0 0;
		}

		&_title {}

		&_txt {
			margin: 15px 0 0;
			font-size: rem(13px, sp);
		}
	}
}

//  topIntro
//----------------------------------------------------------------------
.topIntro {
	$this: &;
	margin: 100px 40px 0;
	padding: 80px 40px;
	background: #fff;
	border-radius: 30px;

	&_inner {
		@include content-width();
	}

	&_list {
		margin: 50px 0 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0 48px;
	}

	&_item {
		display: flex; // 高さをそろえる指定
		flex-direction: column; // 高さをそろえる指定
	}

	&_num {
		margin: 0;
		@include font-lato();
		font-size: rem(50px, pc);
		line-height: 1;
	}

	&_body {
		flex-grow: 1; // 高さをそろえる指定
		margin: 5px 0 0;
		padding: 20px 0;
		background: $yellow-light;
		border-radius: 30px;
	}

	&_img {
		margin: -67px 0 0;
		text-align: center;
		& > img {
			aspect-ratio: 275/276;
			object-fit: contain;
		}
	}

	&_title {
		margin: 30px 0 0;
		font-weight: bold;
		font-size: rem(22px, pc);
		line-height: 1;
		text-align: center;
	}

	&_txt {
		margin: 0;
		padding: 25px 30px 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topIntro {
		$this: &;
		margin: 40px 10px 0;
		padding: 40px 5px;

		&_inner {}

		&_list {
			margin: 30px 0 0;
			display: block;
			& > :first-child {
				margin-top: 0;
			}
		}

		&_item {
			margin: 30px 0 0;
		}

		&_num {
			font-size: rem(40px, sp);
		}

		&_body {}

		&_img {
			padding: 0 30px;
		}

		&_title {
			font-size: rem(17px, sp);
		}

		&_txt {
			padding: 15px 25px 0;
			font-size: rem(13px, sp);
		}
	}
}

//  topGuidance
//----------------------------------------------------------------------
.topGuidance {
	$this: &;
	margin: 80px 0 0;

	&_inner {
		@include content-width();
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topGuidance {
		$this: &;
		margin: 40px 0 0;
		// display: none;

		&_inner {
			margin: 0 20px 0 10px;
		}
	}
}

//  topAttempt
//----------------------------------------------------------------------
.topAttempt {
	$this: &;
	margin: 100px 0 0;

	&_inner {
		@include content-width();
	}

	&_contents {
		display: flex;
		gap: 0 76px;
	}

	&_info {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 50px 0 0;
		width: 336px;
		height: 336px;
		background: $green;
		border-radius: 50%;
	}

	&_link {
		display: block;
		text-decoration: none;
		@media (hover:hover) {
			&:hover {
				opacity: 1;
				.topAttempt_title {
					color: $green;
				}
				.topAttempt_body {
					&::after {
						opacity: 1;
					}
				}
			}
		}
	}

	&_img {
		margin: 0;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		overflow: hidden;
		& > img {
			width: 100%;
			height: 100%;
			aspect-ratio: 375/281;
			object-fit: contain;
		}
	}

	&_body {
		padding: 20px;
		height: 170px;
		position: relative;
		z-index: 1;
		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 50%;
		}
		&::before {
			width: 15px;
			height: 15px;
			background: $green;
			bottom: 20px;
			right: 20px;
			z-index: 3;
		}
		&::after {
			width: 30px;
			height: 30px;
			bottom: 12px;
			right: 12px;
			background: $green-light;
			z-index: 2;
			opacity: 0;
			transition: all .4s;
		}
	}

	&_cate {
		display: inline-block;
	}

	&_title {
		margin: 12px 0 0;
		color: $txtColor;
		font-weight: bold;
		transition: all .4s;
		// ...を表示させるための指定
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 制限したい行数が3の場合 */
		overflow: hidden;
		// ...を表示させるための指定
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topAttempt {
		$this: &;
		margin: 40px 0 0;

		&_inner {
			margin: 0; // swiperをはみ出させるため上書き
		}

		&_contents {
			display: block;
			& > :first-child {
				margin-top: 0;
			}
		}

		&_info {
			margin: 0 auto;
			display: block;
			.topTitle {
				padding: 120px 0 0;
				text-align: center;
			}
		}

		&_link {}

		&_img {}

		&_body {
			padding: 15px;
			height: 140px;
			&::before {
				bottom: 15px;
				right: 15px;
			}
			&::after {
				bottom: 7px;
				right: 7px;
			}
		}

		&_cate {}

		&_title {
			margin: 10px 0 0;
			font-size: rem(13px, sp);
		}
	}
}

//  topNews
//----------------------------------------------------------------------
.topNews {
	$this: &;
	margin: 70px 0 0;

	&_inner {
		@include content-width();
		padding: 50px 60px 60px;
		background: #fff;
		border-radius: 30px;
	}

	&_head {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_body {}
}

@media screen and (max-width: $breakpoint-sp){
	.topNews {
		$this: &;
		margin: 50px 0 0;

		&_inner {
			padding: 30px 20px 25px;
		}

		&_head {
			.topTitle {
				margin: 0 auto;
			}
		}

		&_body {}
	}
}

//  topBanner
//----------------------------------------------------------------------
.topBanner {
	$this: &;
	margin: 60px 0 0;

	&_inner {
		@include content-width();
		& > :first-child {
			margin-top: 0;
		}
	}

	&_list {
		display: grid;
		grid-template-columns: repeat(3, auto);
		justify-content: center;
		gap: 0 24px;
	}

	&_item {
		& > a {
			& > img {
				aspect-ratio: 312/98;
				object-fit: contain;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topBanner {
		$this: &;
		margin: 40px 0 0;

		&_inner {}

		&_list {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 15px 0;
		}

		&_item {}
	}
}

//----------------------------------------------------------------------,
}//pageID-top