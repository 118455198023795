// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.corporateCards {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		display: grid;
		grid-template-columns: 396px auto auto;
		grid-template-rows: repeat(2, 1fr);
		gap: 20px;
	}

	&_item {
		& > a {
			display: block;
			color: inherit;
			text-decoration: none;
			&:hover {
				opacity: 1;
				& > #{$this}_title {
					color: $green;
				}
			}
		}

		//  order
		//----------------------------------------------------------------------
		&-01 {
			grid-row: 1 / span 2; // span 2 2個先まで伸ばす 1 / 3と同義
			grid-column: 1 / 2;
		}
		&-02 {
			// grid-row: 1 / 2;
			// grid-column: 2 / 3;
		}
		&-03 {
			// grid-row: 1 / 2;
			// grid-column: 3 / 4;
		}
		&-04 {
			// grid-row: 2 / 3;
			// grid-column: 2 / 3;
		}
		&-05 {
			// grid-row: 2 / 3;
			// grid-column: 3 / 4;
		}
	}

	&_img {
		margin: 0;
		& > img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			border-radius: 25px;
		}
	}

	&_title {
		margin: 10px 0 0;
		padding-left: 20px;
		font-size: rem(18px, pc);
		font-weight: bold;
		transition: all .4s;
		position: relative;
		&::before {
			content: '';
			width: 10px;
			height: 10px;
			background: $green;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.corporateCards {
		$this: &;

		&_list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: initial;
			max-width: 335px;
			margin: 0 auto;
		}

		&_item {
			//  order
			//----------------------------------------------------------------------
			&-01 {
				grid-row: 1 / 2;
				grid-column: 1 / span 2;
			}
			&-02 {
				// grid-row: 1 / 2;
				// grid-column: 2 / 3;
			}
			&-03 {
				// grid-row: 1 / 2;
				// grid-column: 3 / 4;
			}
			&-04 {
				// grid-row: 2 / 3;
				// grid-column: 2 / 3;
			}
			&-05 {
				// grid-row: 2 / 3;
				// grid-column: 3 / 4;
			}
		}

		&_img {}

		&_title {
			margin: 5px 0 0;
			font-size: rem(16px, sp);
		}
	}
}