// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine2

見出し3

Markup:
<h3 class="headLine2">見出し3</h3>

Styleguide #{$guideNum}
*/
.headLine2 {
	$this: &;
	margin: 40px 0 30px;
	color: $green;
	font-weight: bold;
	font-size: rem(22px, pc);
	line-height: 1.4;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine2 {
		$this: &;
	}
}
