// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
cate

カテゴリースタイル

Markup:
<p class="cate pages_cate" style="color: #7fbf26; border-color: #7fbf26;">カテゴリ1</p><!-- /.pages_cate -->
<p class="cate articleHeader_cate" style="color: #7fbf26; border-color: #7fbf26;">カテゴリ1</p><!-- /.articleHeader_cate -->

Styleguide #{$guideNum}
*/
.cate {
	$this: &;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0 10px;
	font-size: rem(12px, pc);
	font-weight: bold;
	border: 1px solid transparent;
	text-align: center;

	//  color
	//----------------------------------------------------------------------
	&-white {

	}

	//  size
	//----------------------------------------------------------------------
	&-ss {
		min-width: 94px;
		min-height: 23px;
		font-size: rem(11px, pc);
		line-height: 23px;
	}

	&-s {
		min-width: 130px;
		min-height: 30px;
		line-height: 30px;
	}

	&-l {
		padding: 4px 30px;
		font-size: rem(16px, pc);
	}

	//  icon
	//----------------------------------------------------------------------
	&-afterCaretDown {
		&::after {
			@include icons($icons-caret-down);
			font-size: .5em;
			line-height: 1;
			margin: 4px 0 0 5px;
		}
	}
}

@media (hover:hover) {
	.cate {
		$this: &;
		transition: all .4s;
		&:hover {
			opacity: 1;
		}

		//  color
		//----------------------------------------------------------------------
		&-white {
			&:hover {
				color: #fff;
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.cate {
		$this: &;

		//  size
		//----------------------------------------------------------------------
		&-s {
			min-width: 100px;
			min-height: 25px;
			font-size: rem(10px, sp);
			line-height: 25px;
		}
	}
}