// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
roundedTitles

roundedTitles

Markup:
<div class="roundedTitles">
	<ul class="roundedTitles_list">
		<li class="roundedTitles_item">教材の歴史</li><!-- /.roundedTitles_item -->
		<li class="roundedTitles_item">その他</li><!-- /.roundedTitles_item -->
	</ul><!-- /.roundedTitles_list -->
</div><!-- /.roundedTitles -->

Styleguide #{$guideNum}
*/
.roundedTitles {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		&-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 100px;
		}
	}

	&_item {
		color: #fff;
		font-weight: bold;
		text-align: center;
		border-radius: 999px;

		//  triangle
		//----------------------------------------------------------------------
		&-triangle {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				bottom: -18px;
				left: 50%;
				transform: translateX(-50%);
				width: 20px;
				height: 18px;
				clip-path: polygon(0 0, 50% 100%, 100% 0); // 逆三角形
				background: $gray;
			}
		}

		//  size
		//----------------------------------------------------------------------
		&-l {
			font-size: rem(40px, pc);
		}

		&-m {
			font-size: rem(28px, pc);
		}

		&-s {
			padding: 8px;
			font-size: rem(22px, pc);
		}

		//  color
		//----------------------------------------------------------------------
		&-blue {
			background: $blue;
			&::after {
				background: $blue;
			}
		}

		&-green {
			background: $green;
			&::after {
				background: $green;
			}
		}

	}
}

@media screen and (max-width: $breakpoint-sp){
	.roundedTitles {
		$this: &;

		&_list {
			&-grid {
				// gap: 0 30px;
				gap: 0 85px;
			}
		}

		&_item {}
	}
}
