// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine4

見出し5

Markup:
<h5 class="headLine4">見出し5</h5>

Styleguide #{$guideNum}
*/
.headLine4 {
	$this: &;
	margin: 30px 0 20px;
	color: $pink;
	font-weight: bold;
	font-size: rem(18px, pc);
	line-height: 1.4;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine4 {
		$this: &;
	}
}
