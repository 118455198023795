// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
qaPanel

qaPanel

Markup:
<div class="qaPanel">
	<p class="qaPanel_title">〈書店様用コンテンツ〉</p><!-- /.qaPanel_title -->
	<a href="" class="qaPanel_link">https://www.bunri.co.jp/bookshop/faq.html</a><!-- /.qaPanel_link -->
</div><!-- /.qaPanel -->

Styleguide #{$guideNum}
*/
.qaPanel {
	$this: &;
	padding: 40px 25px;
	background: #fff;
	border-radius: 20px;
	& > :first-child {
		margin-top: 0;
	}

	&_title {
		margin: 30px 0 0;
		font-size: rem(18px, pc);
		font-weight: bold;
	}

	&_link {
		color: inherit;
		text-underline-offset: 9px;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.qaPanel {
		$this: &;

		&_title {
			margin: 20px 0 0;
		}

		&_link {}
	}
}
