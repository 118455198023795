// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
ボタンレイアウト

btns

Markup:
<div class="btns {$modifiers}">
	<ul class="btns_list">
		<li class="btns_item btns_item-3-12pc btns_item-5-12sp"><a href="" class="btn">PC: 3/12　SP: 5/12</a></li>
		<li class="btns_item btns_item-3-12pc btns_item-5-12sp"><a href="" class="btn">PC: 3/12　SP: 5/12</a></li>
		<li class="btns_item btns_item-3-12pc btns_item-12-12sp"><a href="" class="btn">PC: 3/12　SP: 12/12</a></li>
	</ul>
</div><!-- /.btns -->

.btns-left - 左寄せ
.btns-center - 中央寄せ
.btns-right - 右寄せ
.btns-vat - 上揃え
.btns-vam - 上下中央揃え
.btns-vab - 下揃え
.btns-v - 縦並び

Styleguide #{$guideNum}
*/
.btns {
	$this: &;
	$gap: 20px;
	margin: 20px 0 0;
	&_list {
		margin: 20px 0 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		gap: var(--row-gap, #{$gap}) var(--column-gap, #{$gap});
	}
	&-v {
		#{$this}_list {
			flex-direction: column;
			align-items: center;
		}
	}
	&-left {
		#{$this}_list { justify-content: flex-start; }
	}
	&-center {
		#{$this}_list { justify-content: center; }
	}
	&-right {
		#{$this}_list { justify-content: flex-end; }
	}
	&-vat {
		#{$this}_list { align-items: flex-start; }
	}
	&-vam {
		#{$this}_list { align-items: center; }
	}
	&-vab {
		#{$this}_list { align-items: flex-end; }
	}
	&_item {
		& > :first-child {
			margin-top: 0;
		}
		& > .btn {
			min-height: 100%;
		}
		@for $i from 1 to 13 {
			&-#{$i}-12 {
				width: calc( (100% + var(--column-gap, #{$gap})) / 12 * #{$i} - var(--column-gap, #{$gap}) );
			}
		}
		@for $i from 1 to 11 {
			&-#{$i}-10 {
				width: calc( (100% + var(--column-gap, #{$gap})) / 10 * #{$i} - var(--column-gap, #{$gap}) );
			}
		}
	}
}
@media screen and (min-width: $breakpoint-sp + 1){
	.btns {
		$this: &;
		$gap: 20px;
		&_list {
			gap: var(--row-gap-pc, #{$gap}) var(--column-gap-pc, #{$gap});
		}
		&-vPc {
			#{$this}_list {
				flex-direction: column;
				align-items: center;
			}
		}
		&-leftPc {
			#{$this}_list { justify-content: flex-start; }
		}
		&-centerPc {
			#{$this}_list { justify-content: center; }
		}
		&-rightPc {
			#{$this}_list { justify-content: flex-end; }
		}
		&-vatPc {
			#{$this}_list { align-items: flex-start; }
		}
		&-vamPc {
			#{$this}_list { align-items: center; }
		}
		&-vabPc {
			#{$this}_list { align-items: flex-end; }
		}
		&_item {
			@for $i from 1 to 13 {
				&-#{$i}-12pc {
					width: calc( (100% + var(--column-gap-pc, #{$gap})) / 12 * #{$i} - var(--column-gap-pc, #{$gap}) );
				}
			}
			@for $i from 1 to 11 {
				&-#{$i}-10pc {
					width: calc( (100% + var(--column-gap-pc, #{$gap})) / 10 * #{$i} - var(--column-gap-pc, #{$gap}) );
				}
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.btns {
		$this: &;
		$gap: 20px;
		&_list {
			gap: var(--row-gap-sp, #{$gap}) var(--column-gap-sp, #{$gap});
		}
		&-vSp {
			#{$this}_list {
				flex-direction: column;
				align-items: center;
			}
		}
		&-leftPc {
			#{$this}_list { justify-content: flex-start; }
		}
		&-centerPc {
			#{$this}_list { justify-content: center; }
		}
		&-rightPc {
			#{$this}_list { justify-content: flex-end; }
		}
		&-vatPc {
			#{$this}_list { align-items: flex-start; }
		}
		&-vamPc {
			#{$this}_list { align-items: center; }
		}
		&-vabPc {
			#{$this}_list { align-items: flex-end; }
		}
		&_item {
			@for $i from 1 to 13 {
				&-#{$i}-12sp {
					width: calc( (100% + var(--column-gap-sp, #{$gap})) / 12 * #{$i} - var(--column-gap-sp, #{$gap}) );
				}
			}
			@for $i from 1 to 11 {
				&-#{$i}-10sp {
					width: calc( (100% + var(--column-gap-sp, #{$gap})) / 10 * #{$i} - var(--column-gap-sp, #{$gap}) );
				}
			}
		}
	}
}