// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
.color

フォントカラー

Markup:
<p class="{$modifiers}">フォントカラー</p>

.color-green - green
.color-blue - blue
.color-pink - pink
.color-gray - gray
.color-white - #fff
.color-black - black

Styleguide #{$guideNum}
*/
.color {
	&-green { color: $green !important; }
	&-blue { color: $blue !important; }
	&-pink { color: $pink !important; }
	&-red { color: $red !important; }
	&-gray { color: $gray !important; }
	&-white { color: #fff !important; }
	&-black { color: $black !important; }
}
