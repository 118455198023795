@import '../_config';
@import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.topAttempt {
	$this: &;

	&_swiperArea {
		margin: 0 calc(50% - 50vw) 0 -1px; // 右側だけ画面いっぱいに
		padding: 0 0 60px;
		padding-left: -100px;
		width: 94.8%; // これがないと表示されない
		overflow: hidden; // 可変時の横スクロール対策
		position: relative; // pagination, buttonを外に出す
	}

	&_swiper {
		position: static;
	}

	&_wrapper {
		margin: 0;
	}

	&_slide {
		height: auto; // 高さをそろえる指定（子要素にheight:100%;）
		background: #fff;
		border-radius: 30px;
	}

	&_pagination {
		left: 383px;
		bottom: 6px;
		width: initial;
		@include font-lato();
		font-size: rem(14px, pc);
		line-height: 1;
	}

	&_button {
		&-prev,
		&-next {
			width: 15px;
			height: 25px;
			top: initial;
			bottom: 0;
			&::after {
				content: '';
				width: 15px;
				height: 25px;
			}
		}

		&-prev {
			left: 0;
			&::after {
				background: url(/assets/images/top/swiper-button-prev.png);
			}
		}

		&-next {
			left: 774px;
			// right: 350px; // 可変するときにズレるのでleftで指定
			&::after {
				background: url(/assets/images/top/swiper-button-next.png);
			}
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.topAttempt {
		$this: &;

		&_swiperArea {
			margin-top: -150px;
			padding: 0 0 50px;
			width: 100%;
		}

		&_swiper {}

		&_wrapper {}

		&_slide {}

		&_pagination {
			left: 50%;
			transform: translateX(-50%);
		}

		&_button {
			&-prev {
				left: 15px;
			}

			&-next {
				left: initial; // これを指定しないとrightが効かない
				right: 15px;
			}
		}
	}
}

//  swiper独自クラスに直接上書き
//----------------------------------------------------------------------

// pagination: {
// 	el: '.swiper-pagination',
// 	type: "fraction", これを指定すると以下
// },

// 自動的に以下のクラスが生成される（ブラウザ上のみ表示）
// .swiper-pagination-current
// .swiper-pagination-total

.swiper-pagination {
	$this: &;

	&-current,
	&-total {
		position: relative;
	}

	&-current:before,
	&-total:after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 255px;
		height: 1px;
		background: #000;
	}

	&-current {
		margin-right: -2px;
		&::before {
			left: -280px;
		}
	}

	&-total {
		margin-left: -2px;
		&::after {
			right: -280px;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.swiper-pagination {
		$this: &;

		&-current,
		&-total {}

		&-current:before,
		&-total:after {
			width: 90px;
			height: 1px;
		}

		&-current {
			&::before {
				left: 45px;
			}
		}

		&-total {
			&::after {
				right: 45px;
			}
		}
	}
}