@import '../_config';
@import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
toTop

toTop

Markup:
<div class="toTop"><a href="javascript: void(0)"></a></div><!-- /.toTop -->

Styleguide #{$guideNum}
*/
.toTop {
	$this: &;
	position: sticky;
	bottom: 0;
	z-index: z(toTop);
	& > a {
		position: absolute;
		right: 20px;
		bottom: 10px;
		width: 60px;
		height: 60px;
		background: $green;
		border: 1px solid transparent;
		border-radius: 50%;
		// transition: all .4s;
		text-decoration: none;
		// @media (hover:hover) {
		// 	&:hover {
		// 		opacity: 1;
		// 		background: #fff;
		// 		border: 1px solid $green;
		// 		&::before {
		// 			color: $green;
		// 		}
		// 	}
		// }
		&::before {
			@include icons($icons-caret-up);
			font-size: 1em;
			color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.toTop {
		$this: &;
		& > a {
			width: 40px;
			height: 40px;
			right: 10px;
			&::before {
				font-size: .7em;
			}
		}
	}
}