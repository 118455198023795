// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.qaTab {
	$this: &;
	margin: 60px 0 0;
	& > :first-child {
		margin-top: 0;
	}

	&_list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
		margin: 0 0 40px;
		padding: 0 45px;
		border-bottom: 1px solid $green;
	}

	&_item {}

	&_btn {
		padding: 11px;
		color: $green;
		font-weight: bold;
		border-top-left-radius: 18px;
		border-top-right-radius: 18px;
		transition: all .4s;
		&:hover {
			color: #fff;
			background: $green;
			opacity: 1;
		}
	}

	&_content {}
}

@media screen and (max-width: $breakpoint-sp){
	.qaTab {
		$this: &;
		margin: 40px 0 0;

		&_list {
			display: block;
			padding: 0 10px;
		}

		&_item {
			margin: 10px 0 0;
		}

		&_btn {}

		&_content {}
	}
}