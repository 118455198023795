// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
naruhodoTitle

トップとcorporate/thought/で使ってる

Markup:
<p class="naruhodoTitle">
	子どもたちの<span class="color-green">「なるほど！」</span>をつくり、<br>
	学力向上に貢献します
</p><!-- /.naruhodoTitle -->

Styleguide #{$guideNum}
*/
.naruhodoTitle {
	$this: &;
	margin: 30px 0 0;
	font-size: rem(40px, pc);
	font-weight: bold;
	@include line-height(60, 40);
	text-align: center;
}

@media screen and (max-width: $breakpoint-sp){
	.naruhodoTitle {
		$this: &;
		margin: 20px 0 0;
		font-size: rem(20px, sp);
		@include letter-spacing(-12);
	}
}
