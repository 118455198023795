// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
cateSide

cateSide

Markup:
<div class="cateSide">
	<div class="cateSide_header">会社案内</div><!-- /.cateSide_header -->
	<div class="cateSide_body">
		<ul class="cateSide_list">
			<li class="cateSide_item"><a href="/corporate/greeting/" class="cateSide_link">社長挨拶</a></li><!-- /.cateSide_item -->
			<li class="cateSide_item"><a href="/corporate/thought/" class="cateSide_link">文理の想い</a></li><!-- /.cateSide_item -->
			<li class="cateSide_item"><a href="/corporate/compliance/" class="cateSide_link">教科書準拠と文理</a></li><!-- /.cateSide_item -->
			<li class="cateSide_item"><a href="/corporate/history/" class="cateSide_link">文理の歩み</a></li><!-- /.cateSide_item -->
			<li class="cateSide_item"><a href="/corporate/overview/" class="cateSide_link">会社概要</a></li><!-- /.cateSide_item -->
		</ul><!-- /.cateSide_list -->
	</div><!-- /.cateSide_body -->
</div><!-- /.cateSide -->

Styleguide #{$guideNum}
*/
.cateSide {
	$this: &;

	&_header {
		padding: 0 18px 13px;
		font-weight: bold;
		border-bottom: 1px solid $yellow-dark;
		line-height: 1;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 4px;
			left: 0;
			width: 10px;
			height: 10px;
			background: $green;
			border-radius: 50%;
		}
	}

	&_body {
		margin: 15px 0 0;
		& > :first-child {
			margin-top: 0;
		}
	}

	&_list {}

	&_item {
		// & > a {
		// 	display: block;
		// 	padding: 0 0 0 13px;
		// 	color: inherit;
		// 	font-size: rem(14px, pc);
		// 	text-decoration: none;
		// 	transition: all .4s;
		// 	position: relative;
		// 	&::before {
		// 		@include icons($icons-caret-right);
		// 		font-size: .5em;
		// 		font-weight: bold;
		// 		position: absolute;
		// 		top: 7px;
		// 		left: 0;
		// 	}
		// 	@media (hover:hover) {
		// 		&:hover {
		// 			color: $green;
		// 			font-weight: bold;
		// 			opacity: 1;
		// 		}
		// 	}
		// }

		// &-isActive {
		// 	& > a {
		// 		color: $green;
		// 		font-weight: bold;
		// 	}
		// }
	}

	&_link {
		display: block;
		padding: 0 0 0 13px;
		color: inherit;
		font-size: rem(14px, pc);
		text-decoration: none;
		transition: all .4s;
		position: relative;
		&::before {
			@include icons($icons-caret-right);
			font-size: .5em;
			font-weight: bold;
			position: absolute;
			top: 7px;
			left: 0;
		}
		@media (hover:hover) {
			&:hover {
				color: $green;
				font-weight: bold;
				opacity: 1;
			}
		}

		&-isActive {
			color: $green;
			font-weight: bold;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.cateSide {
		$this: &;

		&_header {
			&::before {
				top: 3px;
			}
		}

		&_body {}

		&_list {}

		&_item {
			// & > a {
			// 	padding: 3px 13px 3px;
			// 	&::before {
			// 		top: 10px;
			// 	}
			// }
		}

		&_link {
			padding: 3px 13px 3px;
			&::before {
				top: 10px;
			}
		}
	}
}
