/*
pager

ページャ

Markup:
<nav class="pager">
	<div class="pager_inner">
		<ul class="pager_list">
			<li class="pager_prev"><a href=""></a></li>
			<li class="pager_item"><a href="">1</a></li>
			<li class="pager_dot"><span>…</span></li>
			<li class="pager_item hidden-sp"><a href="">48</a></li>
			<li class="pager_item hidden-sp"><a href="">49</a></li>
			<li class="pager_item pager_item-active"><a href="">50</a></li>
			<li class="pager_item hidden-sp"><a href="">51</a></li>
			<li class="pager_item hidden-sp"><a href="">52</a></li>
			<li class="pager_dot"><span>…</span></li>
			<li class="pager_item"><a href="">100</a></li>
			<li class="pager_next"><a href=""></a></li>
		</ul>
	</div>
</nav><!-- /.pager -->

Styleguide #{$guideNum}
*/
.pager {
	$this: &;
	margin: 50px 0 0;
	&_inner {
		margin: 0 auto;
	}
	&_list {
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		align-items: center;
	}
	&_item {
		margin: 0 10px;
		& > a {
			display: flex;
			display: -webkit-flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			font-size: rem(16px, pc);
			@include font-lato();
			text-decoration: none;
			color: $txtColor;
		}
		&-active {
			& > a {
				border-color: transparent;
				color: $green;
			}
		}
	}
	&_prev,
	&_next {
		align-self: stretch;
		& > a {
			display: flex;
			display: -webkit-flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			padding: 0 10px;
			line-height: 1;
			font-size: rem(24px, pc);
			text-decoration: none;
			&::before {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				font-size: .5em;
				color: #fff;
				background: $green;
				border-radius: 50%;
			}
		}
	}
	&_prev {
		margin-right: 10px;
		& > a {
			&:before {
				@include icons($icons-caret-left);
			}
		}
	}
	&_next {
		margin-left: 10px;
		& > a {
			&:before {
				@include icons($icons-caret-right);
			}
		}
	}
	&_dot {
		& > span {
			font-size: 14px;
			vertical-align: sub;
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pager {
		$this: &;
		&_inner {
		}
		&_list {
			position: relative;
		}
		&_item {
			&-active {
			}
		}
		&_dot {
			font-size: 10px;
		}
		&_prev,
		&_next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&_prev {
			left: 0;
		}
		&_next {
			right: 0;
		}
	}
}

@media screen and (max-width: $breakpoint-sp) {
	.hidden-sp {
		display: none !important;
	}
}

@media screen and (min-width: $breakpoint-sp + 1) {
	.hidden-pc {
		display: none !important;
	}
}
